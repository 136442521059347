import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Box,
  Divider,
  TextField,
  MenuItem,
  Popper,
  CircularProgress,
  Typography,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { toast } from "react-toastify";
import { Formik, Field, Form, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  JurisdictionData,
  SaveJurisdictionData,
  GetApplicationData,
  RemoveSubActivityApi,
  apiCallWithAxiosCancel,
  AppStepLock,
} from "../../helpers/api";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import LoadingModal from "../../helpers/LoadingModal";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import MinimizeIcon from "@mui/icons-material/Minimize";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
// import JR_animation from "../../assets/images/output-onlinegiftools.gif"

import JR_animation from "../../assets/images/Fox_Loading.gif";

import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { set } from "date-fns";
import { MdClose } from "react-icons/md";
import {
  handleArrowClick,
  valueTester,
} from "../../Utilities/UtilityFunctions";
import { ExclaimationIcon } from "../../assets/svgs/svgs";
import { blockStep } from "../../features/stepsBlocker";

// fox loading video
// const JR_animation = "https://firmfox-loader.s3.us-east-1.amazonaws.com/FIR_Loading+animation+1_v3_COMP.mp4"

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "16px", // Adjust radius as needed
  },
}));

// Dummy data for demonstration

const validationSchema = Yup.object().shape({
  visa_for_manager: Yup.string()
    .trim()
    .required("Visa for managers current outside of the UAE is required")
    .min(0),
  visa_for_manager_inside: Yup.string()
    .trim()
    .required("Visa for managers currently in UAE is required")
    .min(0),

  visa_for_investor: Yup.string()
    .trim()
    .required("Visa for investor/partner currently ouside UAE is required")
    .min(0),
  // visa_for_investor_inside: Yup.string().trim(),
  // .required("Visa for investor/partner currently in UAE is required").min(0),

  officeSpace: Yup.string().trim().required("Office space type is required"),
  selectedJurisdiction: Yup.object().shape({
    id: Yup.string().required("Please select a jurisdiction before proceeding"),
  }),
});

function SelectJurisdiction({
  onNext,
  onBack,
  onSaveAndExit,
  setMaxStepReached,
}) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedJurisdictionID, setSelectedJurisdictionID] = useState(null);
  const [serverJurisdictionData, setServerJurisdictionData] = useState(null);
  const [nextLoading, setNextLoading] = useState(false);
  const [valuesJurisdiction, setValuesJurisdiction] = useState([]);
  const [initialValues, setInitialValues] = useState({
    visa_for_manager: "0",
    visa_for_manager_inside: "0",
    officeSpace: "Virtual/Flexi Office",
    selectedJurisdiction: "",
    visa_for_investor: "0",
    visa_for_investor_inside: "0",
  });
  const applicationID = useSelector((state) => state.app.applicationID);
  const [suggestedJurisdictions, setSuggestedJurisdictions] = useState([]);
  const [otherJurisdictions, setOtherJurisdictions] = useState([]);
  const [jr, setJr] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedJurisdiction, setSelectedJurisdiction] = useState(null);
  const [openOtherDialog, setOpenOtherDialog] = useState(false);
  const [selectedOtherJurisdiction, setSelectedOtherJurisdiction] =
    useState(null);
  const [isMainLand, setIsMainLand] = useState(false);
  const [isFreeZone, setIsFreeZone] = useState(false);
  const [allData, setAllData] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEverythingDisabled, setIsEverythingDisabled] = useState(false);
  const [isEmptyJurisdiction, setIsEmptyJurisdiction] = useState();
  const [isApiCalled, setIsApiCalled] = useState(false);

  const [appComplete, setAppComplete] = useState(false);
  //selected activities from business activities:
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [subActivityId, setSubActivityId] = useState([]);
  const [extraValue, setExtraValue] = useState([]);

  const [deletingSubActivity, setDeletingSubActivity] = useState(false);
  const jurisdictionContainer = useRef(null);
  const boxRef = useRef(null); // Create a ref for the Box
  const [boxHeight, setBoxHeight] = useState(0); // State to store the height

  const appLock = useSelector((state) => state.stepBlocker);

  // Auto-scroller and slider functions:
  const [scrollIntervalId, setScrollIntervalId] = useState(null);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const jurisdictionFormik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setErrors }) => {
      handleSubmit({
        ...values,
        setErrors,
        selectedJurisdiction: { ...selectedJurisdiction },
      });
    },
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener on mount
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log("selectedOtherJurisdiction", selectedOtherJurisdiction);
  });

  // Function to handle when the mouse is pressed down or touch starts
  const handleMouseDown = (direction) => {
    const intervalId = setInterval(
      () => handleArrowClick({ direction, ref: jurisdictionContainer }),
      100
    ); // Adjust scrolling speed (100ms interval)
    setScrollIntervalId(intervalId);
  };

  // Function to clear the interval when the mouse/touch is released
  const handleMouseUp = () => {
    if (scrollIntervalId) {
      clearInterval(scrollIntervalId);
      setScrollIntervalId(null);
    }
  };

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        // Get the height of the Box
        setBoxHeight(entry.contentRect.height);
      }
    });

    if (boxRef.current) {
      observer.observe(boxRef.current); // Start observing the Box
    }

    return () => {
      // Clean up observer on unmount
      if (boxRef.current) {
        observer.unobserve(boxRef.current);
      }
    };
  }, []);

  const RemoveSubActivity = async (activity) => {
    setDeletingSubActivity(true);

    // Find the activity object based on the name
    const activityObj = extraValue?.find(
      (subActivity) => subActivity.activityNameEn === activity
    );

    // console.log("the activity object is ", extraValue);

    if (extraValue?.length > 1) {
      try {
        const removeSubActivityApi = await RemoveSubActivityApi(
          { app_id: applicationID, activity_id: activityObj?.id },
          dispatch
        );

        // Remove the activity name from selectedActivities
        setSelectedActivities((prev) =>
          prev.filter((selectedActivity) => selectedActivity !== activity)
        );

        // Remove the corresponding activity ID from subActivityId
        if (activityObj) {
          setSubActivityId((prev) =>
            prev.filter((id) => id !== activityObj?.id)
          );
          setExtraValue((prev) => {
            return [...prev].filter(
              (item) => `${item?.id}` !== `${activityObj?.id}`
            );
          });
        }
        setDeletingSubActivity(false);
      } catch (error) {
        // failed to remove sub activity
        toast.error("Failed to remove the selected activity", {
          toastId: "failed-to-remove-biz-id",
        });
        setDeletingSubActivity(false);
      }
    } else {
      toast.warning("You need to keep at least one business activity", {
        toastId: "at-least-one-biz-id",
      });
      setDeletingSubActivity(false);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleGlobalClick = (event) => {
    // If anchorEl is not null and left mouse button is clicked
    if (anchorEl !== null && event.button === 0) {
      // Check if the click happened on the specific SVG (prevent action if it does)
      if (event.target.closest("svg.visa-req-svg")) {
        return; // Don't fire the function if SVG is clicked
      }
      handleClick(event); // Call the existing handleClick function
    }
  };

  useEffect(() => {
    // Add the global click listener
    document.addEventListener("mousedown", handleGlobalClick);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleGlobalClick);
    };
  }, [anchorEl]);

  const openPopper = Boolean(anchorEl);
  const id = openPopper ? "simple-popper" : undefined;

  const handleClickOpen = (jurisdiction) => {
    setSelectedJurisdiction(jurisdiction);
    console.log("selected jurisdiction is = ", jurisdiction);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // setSelectedJurisdiction(null);
  };

  // Sample data
  const handleClickOpenOther = (jurisdiction) => {
    setSelectedOtherJurisdiction(jurisdiction);
    setOpenOtherDialog(true);
  };

  const handleCloseOther = () => {
    setOpenOtherDialog(false);
    // setSelectedOtherJurisdiction(null);
  };

  const handleIsEverythingDisabled = (strategy) => {
    if (strategy === "next") {
      // console.log("next clicked")
      onNext();
    } else {
      navigate("/");
    }
  };

  const [partners, setParters] = useState([]);

  useEffect(() => {
    console.log("initial values", initialValues);
    jurisdictionFormik.resetForm({ values: initialValues });
  }, [initialValues]);

  useEffect(() => {
    async function fetchApplicationData() {
      try {
        // setLoading(true);
        setNextLoading(true);
        const applicationData = await GetApplicationData(
          applicationID,
          dispatch
        );

        // // Use the cancellation logic for fetching application data
        // const applicationData = await apiCallWithAxiosCancel((cancelToken) => {
        //   console.log("cancel = ", cancelToken)
        //   GetApplicationData(applicationID, dispatch, cancelToken)
        // })

        const applicationCompletionStatus = applicationData?.data[0]
          ?.is_completed
          ? applicationData?.data[0]?.is_completed
          : false;
        console.log("app complete = ", applicationCompletionStatus);
        setAppComplete(applicationCompletionStatus);
        const subActivityIds = applicationData?.data[0]?.sub_activities.map(
          (item) => item.id
        );

        setSubActivityId(subActivityIds);

        const subActivityNameEns = applicationData.data[0].sub_activities.map(
          (item) => item.activityNameEn
        );
        setExtraValue([...applicationData?.data[0]?.sub_activities]);
        setSelectedActivities(subActivityNameEns);

        // console.log("the application data", applicationData?.data[0]?.costing[0]?.visa_for_manager);
        setParters([...applicationData?.data?.[0]?.partner_details]);
        setMaxStepReached(applicationData?.data[0]?.step_no + 1);
        setIsEverythingDisabled(applicationData?.data[0]?.is_completed);
        if (applicationData && applicationData?.data) {
          const fetchedData = applicationData?.data[0];

          if (fetchedData?.jurisdiction !== null) {
            setSelectedJurisdictionID(fetchedData?.jurisdiction[0]?.id);
            setServerJurisdictionData(fetchedData?.jurisdiction[0]?.id);
            setValuesJurisdiction(fetchedData?.costing);
            setInitialValues({
              visa_for_manager:
                fetchedData?.costing?.find(
                  (item) => item["visa_for_manager"]
                )?.["visa_for_manager"] || "0",
              visa_for_investor:
                fetchedData?.costing?.find(
                  (item) => item["visa_for_investor"]
                )?.["visa_for_investor"] || "0", // Corrected field name

              visa_for_manager_inside:
                fetchedData?.costing?.find(
                  (item) => item["visa_for_manager_inside"]
                )?.["visa_for_manager_inside"] || "0",
              visa_for_investor_inside: "0", // Corrected field name

              officeSpace:
                fetchedData?.costing?.find((item) => item["workspace_type"])?.[
                  "workspace_type"
                ] || "",
              selectedJurisdiction: fetchedData?.jurisdiction[0]?.id || "",
              jurisdictionType: "Not sure",
            });

            // set form values - useable formik functions through useFormik Hook
            jurisdictionFormik.setValues({
              visa_for_manager:
                fetchedData?.costing?.find(
                  (item) => item["visa_for_manager"]
                )?.["visa_for_manager"] || "0",
              visa_for_investor:
                fetchedData?.costing?.find(
                  (item) => item["visa_for_investor"]
                )?.["visa_for_investor"] || "0", // Corrected field name

              visa_for_manager_inside:
                fetchedData?.costing?.find(
                  (item) => item["visa_for_manager_inside"]
                )?.["visa_for_manager_inside"] || "0",
              visa_for_investor_inside: "0", // Corrected field name

              officeSpace:
                fetchedData?.costing?.find((item) => item["workspace_type"])?.[
                  "workspace_type"
                ] || "",
              selectedJurisdiction: fetchedData?.jurisdiction[0]?.id || null,
              jurisdictionType: "Not sure",
            });

            await handleApiRequest({
              // visa_for_manager: fetchedData?.costing[1]?.visa_for_manager || "0",
              // visa_for_investor: fetchedData?.costing[0]?.visa_for_investor || "0",

              // visa_for_manager_inside: fetchedData?.costing[1]?.visa_for_manager_inside || "0",
              // visa_for_investor_inside: fetchedData?.costing[0]?.visa_for_investor_inside || "0",

              //officeSpace: fetchedData?.costing[5]?.workspace_type || "",

              visa_for_manager:
                fetchedData?.costing?.find(
                  (item) => item["visa_for_manager"]
                )?.["visa_for_manager"] || "0",
              visa_for_investor:
                fetchedData?.costing?.find(
                  (item) => item["visa_for_investor"]
                )?.["visa_for_investor"] || "0", // Corrected field name

              visa_for_manager_inside:
                fetchedData?.costing?.find(
                  (item) => item["visa_for_manager_inside"]
                )?.["visa_for_manager_inside"] || "0",
              visa_for_investor_inside: "0", // Corrected field name

              officeSpace:
                fetchedData?.costing?.find((item) => item["workspace_type"])?.[
                  "workspace_type"
                ] || "",
            });
          }
        } else {
          toast.error("Unexpected response structure", {
            toastId: "res-structure-error",
          });
        }
      } catch (error) {
        toast.error("Error occurred while fetching Application Data", {
          toastId: "app-data-error",
        });
      } finally {
        setLoading(false);
        setNextLoading(false);
      }
    }
    fetchApplicationData();
  }, []);

  const handleSubmit = async (values, exit) => {
    const visa_for_managerNumber = values.visa_for_manager
      ? parseInt(values.visa_for_manager, 10)
      : 0;
    const visa_for_investorNumber = values.visa_for_investor
      ? parseInt(values.visa_for_investor, 10)
      : 0;

    const visa_for_managerNumber_inside = values.visa_for_manager_inside
      ? parseInt(values.visa_for_manager_inside, 10)
      : 0;
    const visa_for_investorNumber_inside = values.visa_for_investor_inside
      ? parseInt(values.visa_for_investor_inside, 10)
      : 0;

    if (
      visa_for_managerNumber +
        visa_for_investorNumber +
        visa_for_managerNumber_inside +
        visa_for_investorNumber_inside >
      15
    ) {
      toast.error(
        "The sum of visas for manager and investor cannot exceed 15.",
        { toastId: "visaError" }
      );
      return; // Prevent the API request from being triggered
    } else {
      try {
        setLoading(true);

        const JurisId = values.selectedJurisdiction?.id
          ? values.selectedJurisdiction?.id
          : selectedJurisdictionID;
        const renewalCost = values.selectedJurisdiction.renewal_cost
          ? values.selectedJurisdiction.renewal_cost
          : valuesJurisdiction[4]?.renewal_cost;
        const upFrontCost = values.selectedJurisdiction.upfront_cost
          ? values.selectedJurisdiction.upfront_cost
          : valuesJurisdiction[3]?.up_front_cost;
        const goodVisa =
          (values.visa_for_manager &&
            values?.visa_for_manager !== "" &&
            values?.visa_for_manager !== null) ||
          values?.visa_for_manager !== undefined
            ? values.visa_for_manager
            : "0";

        const officeType = values.officeSpace
          ? values.officeSpace
          : valuesJurisdiction[5]?.workspace_type;

        // console.log("values = ", values)
        // console.log("valuesJurisdiction", valuesJurisdiction[1])

        const theCostingBreakDown = allData.find(
          (data) => data?.id === JurisId
        );

        const payload = {
          jurisdiction_id: JurisId,
          visa_for_manager: goodVisa,
          visa_for_manager_inside:
            (values.visa_for_manager_inside &&
              values?.visa_for_manager_inside !== "" &&
              values?.visa_for_manager_inside !== null) ||
            values?.visa_for_manager_inside !== undefined
              ? values.visa_for_manager_inside
              : "0",
          workspace_type: officeType,
          app_id: applicationID,
          renewal_cost: renewalCost,
          up_front_cost: upFrontCost,
          visa_for_investor: values.visa_for_investor
            ? values.visa_for_investor
            : "0",
          visa_for_investor_inside: values.visa_for_investor_inside
            ? values.visa_for_investor_inside
            : "0",
          cost_breakdown: theCostingBreakDown.cost_breakdown,
          license_cost_breakdown: theCostingBreakDown.license_cost_breakdown,
          external_approval: theCostingBreakDown.external_approval,
        };
        // Use the cancellation logic for saving jurisdiction data
        const response = await apiCallWithAxiosCancel((cancelToken) =>
          SaveJurisdictionData(payload, dispatch, cancelToken)
        );

        if (appLock.is_blocked === true) {
          await AppStepLock(
            {
              app_id: valueTester(appLock.app_id)
                ? appLock.app_id
                : applicationID,
              is_blocked: true,
              current_step: appLock.currentStep >= 3 ? appLock.currentStep : 3,
            },
            dispatch
          )
            .then(() => {
              dispatch(
                blockStep({
                  is_blocked: true,
                  currentStep:
                    appLock.currentStep >= 3 ? appLock.currentStep : 3,
                  app_id: valueTester(appLock.app_id)
                    ? appLock.app_id
                    : applicationID,
                })
              );
            })
            .catch((error) => {});
        }

        if (exit === true) {
          navigate("/");
        } else {
          onNext();
        }
      } catch (error) {
        if (error?.code !== "ERR_CANCELED" || error?.name !== "CanceledError") {
          console.log(error);
          toast.error(
            `${error?.response?.data?.error || "No jurisidiction selected"}`,
            { toastId: "visaError" }
          );
        }
      } finally {
        setLoading(false);
      }
    }

    // console.log("values = ", values)
  };

  const handleApiRequest = async (values) => {
    const visa_for_managerNumber = values.visa_for_manager
      ? parseInt(values.visa_for_manager, 10)
      : 0;
    const visa_for_investorNumber = values.visa_for_investor
      ? parseInt(values.visa_for_investor, 10)
      : 0;

    const visa_for_managerNumber_inside = values.visa_for_manager_inside
      ? parseInt(values.visa_for_manager_inside, 10)
      : 0;
    const visa_for_investorNumber_inside = values.visa_for_investor_inside
      ? parseInt(values.visa_for_investor_inside, 10)
      : 0;

    if (
      visa_for_managerNumber +
        visa_for_investorNumber +
        visa_for_managerNumber_inside +
        visa_for_investorNumber_inside >
      15
    ) {
      toast.error(
        "The sum of visas for manager and investor cannot exceed 15.",
        { toastId: "visaError" }
      );
      return; // Prevent the API request from being triggered
    }

    try {
      setJr(true);
      setIsApiCalled(true);
      const visa_for_managerNumber =
        values.visa_for_manager &&
        values.visa_for_manager !== null &&
        values.visa_for_manager !== undefined
          ? parseInt(values.visa_for_manager, 10)
          : 0;
      const visa_for_investorNumber =
        values.visa_for_investor &&
        values.visa_for_investor !== null &&
        values.visa_for_investor !== ""
          ? values.visa_for_investor
          : 0;

      const visa_for_managerNumber_inside =
        values.visa_for_manager_inside &&
        values.visa_for_manager_inside !== null &&
        values.visa_for_manager_inside !== undefined
          ? parseInt(values.visa_for_manager_inside, 10)
          : 0;
      const visa_for_investorNumber_inside =
        values.visa_for_investor_inside &&
        values.visa_for_investor_inside !== null &&
        values.visa_for_investor_inside !== ""
          ? values.visa_for_investor_inside
          : 0;

      const payload = {
        workspace_type: values.officeSpace,
        id: applicationID,
        visa_for_investor: visa_for_investorNumber,
        visa_for_manager: visa_for_managerNumber,

        visa_for_manager_inside: visa_for_managerNumber_inside,
        visa_for_investor_inside: visa_for_investorNumber_inside,
      };

      // Trigger the API request
      const response = await JurisdictionData(payload, dispatch);
      setIsEmptyJurisdiction(response?.data);
      const data = response?.data?.suggested_jurisdictions;

      if (data) {
        data?.sort((a, b) => a?.upfront_cost - b?.upfront_cost);
        setAllData(data);

        console.log("all data = ", allData);
        console.log(
          "found jurisdiction",
          selectedJurisdictionID,
          data.find((item) => `${item?.id}` === `${selectedJurisdictionID}`)
        );

        console.log("values = ", values);

        var jurisdiction_type_temp = "Not sure";

        if (isMainLand && !isFreeZone) {
          jurisdiction_type_temp = "Main Land";
        } else if (!isMainLand && isFreeZone) {
          jurisdiction_type_temp = "Free Zone";
        } else {
          jurisdiction_type_temp = "Not sure";
        }

        setInitialValues({
          ...initialValues,
          visa_for_investor: visa_for_investorNumber,
          visa_for_manager: visa_for_managerNumber,
          visa_for_investor_inside: visa_for_investorNumber_inside,
          visa_for_manager_inside: visa_for_managerNumber_inside,
          selectedJurisdiction: data.find(
            (item) => `${item?.id}` === `${selectedJurisdictionID}`
          ),
          officeSpace: valueTester(values?.officeSpace)
            ? values?.officeSpace
            : initialValues?.officeSpace,
          jurisdictionType: jurisdiction_type_temp,
        });

        jurisdictionFormik.setValues({
          ...initialValues,
          visa_for_investor: visa_for_investorNumber,
          visa_for_manager: visa_for_managerNumber,
          visa_for_investor_inside: visa_for_investorNumber_inside,
          visa_for_manager_inside: visa_for_managerNumber_inside,
          selectedJurisdiction: data.find(
            (item) => `${item?.id}` === `${selectedJurisdictionID}`
          ),
          officeSpace: valueTester(values?.officeSpace)
            ? values?.officeSpace
            : initialValues?.officeSpace,
          jurisdictionType: jurisdiction_type_temp,
        });

        // jurisdictionFormik.setFieldValue("selectedJurisdiction", {...data.find((item) => `${item?.id}` === `${selectedJurisdictionID}`)} )

        // setFieldValue("visa_for_manager", visa_for_managerNumber)
        // setFieldValue("visa_for_investor", visa_for_investorNumber)

        // Set the lowest upfront payment jurisdiction as suggested
        setSuggestedJurisdictions([data?.[0]]);

        // Set the other jurisdictions
        setOtherJurisdictions(data?.slice(1));
      } // 2000ms = 2 seconds
    } catch (error) {
      console.error("API request failed", error);
      if (error?.response?.data?.error?.length > 0) {
        toast.warning(error?.response?.data?.error, {
          toastId: "visa and shareholder",
        });
      } else {
        toast.warning("Something went wrong", {
          toastId: "general-jurisdiction-error",
        });
      }
    } finally {
      setTimeout(
        () => {
          setJr(false); // Also delay hiding the loader by 2 seconds
        },
        4000
        //0
      );
    }
  };

  useEffect(() => {
    const data = allData;
    if (
      selectedJurisdictionID !== null &&
      selectedJurisdictionID != "" &&
      selectedJurisdictionID &&
      allData
    ) {
      const selectedJurisdictionTemp = data.find(
        (item) => `${item?.id}` === `${selectedJurisdictionID}`
      );

      setSelectedJurisdiction(selectedJurisdictionTemp);
      jurisdictionFormik.setFieldValue(
        "selectedJurisdiction",
        selectedJurisdictionTemp
      );
      setServerJurisdictionData(selectedJurisdictionTemp?.id);
    }
  }, [selectedJurisdictionID, allData]);

  // increment and decrement visa_for_manager outside
  const handleIncrement = async (setFieldValue, visa_for_manager) => {
    if (!parseInt(visa_for_manager) && parseInt(visa_for_manager) !== 0) {
      await setFieldValue("visa_for_manager", 0);
    } else if (parseInt(visa_for_manager) < 15) {
      const newValue = (parseInt(visa_for_manager) + 1).toString();
      await setFieldValue("visa_for_manager", newValue);
    }
  };

  const handleDecrement = async (setFieldValue, visa_for_manager) => {
    if (!parseInt(visa_for_manager) && parseInt(visa_for_manager) !== 0) {
      await setFieldValue("visa_for_manager", 0);
    } else if (parseInt(visa_for_manager) > 0) {
      const newValue = (parseInt(visa_for_manager) - 1).toString();
      await setFieldValue("visa_for_manager", newValue);
    }
  };

  //handle increment and decrement for visa manager - inside:
  const handleIncrementInside = async (
    setFieldValue,
    visa_for_manager_inside
  ) => {
    if (
      !parseInt(visa_for_manager_inside) &&
      parseInt(visa_for_manager_inside) !== 0
    ) {
      await setFieldValue("visa_for_manager_inside", 0);
    } else if (parseInt(visa_for_manager_inside) < 15) {
      const newValue = (parseInt(visa_for_manager_inside) + 1).toString();
      await setFieldValue("visa_for_manager_inside", newValue);
    }
  };

  const handleDecrementInside = async (
    setFieldValue,
    visa_for_manager_inside
  ) => {
    if (
      !parseInt(visa_for_manager_inside) &&
      parseInt(visa_for_manager_inside) !== 0
    ) {
      await setFieldValue("visa_for_manager_inside", 0);
    } else if (parseInt(visa_for_manager_inside) > 0) {
      const newValue = (parseInt(visa_for_manager_inside) - 1).toString();
      await setFieldValue("visa_for_manager_inside", newValue);
    }
  };

  function formatKey(key) {
    return key
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  // const breakdown = selectedJurisdiction.cost_breakdown[0];
  // console.log("selected jurisdiction", initialValues);
  // console.log("suggested jurisdictions", suggestedJurisdictions);
  // console.log("other jurisdictions", otherJurisdictions);
  // console.log("initialValues", initialValues);
  // console.log("selectedJurisdictionID", selectedJurisdictionID);
  // console.log(" good jhurisa", valuesJurisdiction);
  // console.log("suggested", suggestedJurisdictions[0]?.external_approval);
  // console.log("the jr is", jr)
  // console.log("all data", valuesJurisdiction)
  // console.log("is there any data in it", isEmptyJurisdiction?.length>1)

  const [screenHeight, setScreenHeight] = useState(window.innerHeight); // Initial height

  useEffect(() => {
    const updateHeight = () => {
      setScreenHeight(window.innerHeight); // Update state when window is resized
    };

    // Add event listener for window resize
    window.addEventListener("resize", updateHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const selectJurisdictionRef = useRef();
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // Skip the first render
      return;
    } else if (jurisdictionFormik.errors.selectedJurisdiction) {
      // jurisdictionFormik.setFieldTouched('selectedJurisdiction', true, true);

      selectJurisdictionRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [jurisdictionFormik.errors]);

  return (
    <>
      <Popper
        id={id}
        open={openPopper}
        sx={{ zIndex: 100, maxWidth: { xs: "60%", smc: "20%" } }}
        anchorEl={anchorEl}
      >
        <Box
          sx={{ border: 1, p: 1, bgcolor: "background.paper", borderRadius: 2 }}
        >
          In this field you can enter the number of visas required for the
          manager/employee. The maximum number of visas allowed is 15.
        </Box>
      </Popper>
      <LoadingModal open={nextLoading} fox={true} />

      {jr ? (
        <div
          style={{
            background: "#f9f9f9",
            height: "calc(100% + 0.5rem)",
            width: "100%",
            marginTop: "-0.5rem",
            borderRadius: "20px",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="flex items-center text-center md:w-[100%] w-full justify-center overflow-hidden">
            {/* <video autoPlay loop muted playsInline className="max-h-[100%] w-full">
              <source src={JR_animation} type="video/mp4" />
              Your browser does not support the video tag.
            </video> */}
            <img src={JR_animation} />
          </div>
        </div>
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={jurisdictionFormik.validationSchema}
            onSubmit={(values) =>
              jurisdictionFormik.handleSubmit(
                {
                  ...values,
                  selectedJurisdiction: { ...selectedJurisdiction },
                },
                false
              )
            }
            enableReinitialize
          >
            <Form className="h-full rounded-[20px]">
              <Box
                className="rounded-t-[20px]"
                display={"flex"}
                justifyContent={"space-between"}
                sx={{ minHeight: "100%", background: "white" }}
                flexDirection={"column"}
              >
                <Box
                  className="jurisdiction-upper-container"
                  sx={{ px: { xs: 3, smc: 5 } }}
                  marginTop={2}
                >
                  <div ref={boxRef}>
                    <p className="font-bold text-lg sm:text-2xl text-[#1E1D1B]">
                      Select a Jurisdiction
                    </p>

                    {windowWidth < 640 ? (
                      <hr style={{ borderWidth: "1px", marginTop: "7px" }} />
                    ) : (
                      ""
                    )}

                    <div className="inner-parent-select-js-container">
                      <div className="flex mt-2 w-full sm:w-[100%] flex-col lg:flex-row mb-2 gap-3">
                        <div className="lg:w-[558px] visa-req-field-container">
                          <div className="flex items-center mb-2 gap-2">
                            <p className="font-bold text-start text-[#1E1D1B] text-[16px]">
                              Visas Required{" "}
                            </p>
                            <svg
                              className="visa-req-svg cursor-pointer"
                              aria-describedby={id}
                              type="button"
                              onClick={handleClick}
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.50742 4.91246C8.50742 5.38973 8.13514 5.72383 7.65787 5.72383C7.19014 5.72383 6.81787 5.38973 6.81787 4.91246C6.81787 4.44473 7.19014 4.09155 7.65787 4.09155C8.13514 4.09155 8.50742 4.44473 8.50742 4.91246ZM6.99923 11.0979V6.42064H8.31651V11.0979H6.99923Z"
                                fill="#1E1D1B"
                              />
                              <path
                                d="M7.5 0C6.01664 0 4.56659 0.439867 3.33323 1.26398C2.09986 2.08809 1.13856 3.25943 0.570907 4.62987C0.00324962 6.00032 -0.145275 7.50832 0.144114 8.96318C0.433503 10.418 1.14781 11.7544 2.1967 12.8033C3.2456 13.8522 4.58197 14.5665 6.03682 14.8559C7.49168 15.1453 8.99968 14.9968 10.3701 14.4291C11.7406 13.8614 12.9119 12.9001 13.736 11.6668C14.5601 10.4334 15 8.98336 15 7.5C14.9979 5.51152 14.2071 3.60509 12.801 2.19902C11.3949 0.79295 9.48848 0.00209987 7.5 0ZM7.5 13.8462C6.24485 13.8462 5.01789 13.474 3.97427 12.7766C2.93065 12.0793 2.11725 11.0882 1.63692 9.92857C1.1566 8.76896 1.03092 7.49296 1.27579 6.26193C1.52066 5.03089 2.12507 3.90012 3.01259 3.01259C3.90012 2.12507 5.0309 1.52065 6.26193 1.27579C7.49296 1.03092 8.76896 1.15659 9.92857 1.63692C11.0882 2.11724 12.0793 2.93065 12.7766 3.97426C13.474 5.01788 13.8462 6.24485 13.8462 7.5C13.8442 9.18252 13.175 10.7956 11.9853 11.9853C10.7956 13.175 9.18252 13.8442 7.5 13.8462Z"
                                fill="#1E1D1B"
                              />
                            </svg>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              gap: windowWidth > 390 ? "0.75rem" : "20px",
                            }}
                            className="flex flex-col max-w-[100%] sm:max-w-[inherit] min-w-[203px] md:min-w-[505px] sm:flex-row items-center"
                          >
                            {/* Outside Investor/partner - old field */}
                            <Field
                              InputLabelProps={{
                                shrink: true,
                              }}
                              // sx={{
                              //   "& .MuiFormLabel-root":{
                              //     top: "-15px",
                              //     whiteSpace: "pre-wrap"
                              //   }
                              // }}
                              label={
                                <Tooltip title="Investor/Partner">
                                  <span>Investor/Partner</span>
                                </Tooltip>
                              }
                              as={TextField}
                              name="visa_for_investor"
                              type="number"
                              variant="outlined"
                              fullWidth
                              min={0}
                              disabled={isEverythingDisabled || appComplete}
                              onKeyPress={(e) =>
                                e.key === "Enter" && e.preventDefault()
                              }
                              value={
                                jurisdictionFormik.values.visa_for_investor
                              }
                              error={Boolean(
                                jurisdictionFormik.errors.visa_for_investor &&
                                  jurisdictionFormik.touched.visa_for_investor
                              )}
                              helperText={
                                jurisdictionFormik.errors.visa_for_investor &&
                                jurisdictionFormik.touched.visa_for_investor ? (
                                  <div className="text-red-500">
                                    {
                                      jurisdictionFormik.errors
                                        .visa_for_investor
                                    }
                                  </div>
                                ) : null
                              }
                              onChange={async (e) => {
                                if (
                                  partners.length === 1 &&
                                  partners.some(
                                    (item) => item?.nameEN?.length === 0
                                  ) === false
                                ) {
                                  let value = parseInt(e.target.value, 10);
                                  console.log(
                                    "c1 - e.target.value",
                                    e.target.value,
                                    "\nvalue",
                                    value
                                  );

                                  if (value < 0) value = 0;
                                  if (value > 1) value = 1;
                                  if (
                                    value !== "0" &&
                                    value !== 0 &&
                                    (!value ||
                                      value === undefined ||
                                      value === null ||
                                      value === "")
                                  ) {
                                    value = 0;
                                  }
                                  await jurisdictionFormik.setFieldValue(
                                    "visa_for_investor",
                                    value
                                  );
                                } else {
                                  let value = parseInt(e.target.value, 10);
                                  console.log(
                                    "c2 - e.target.value",
                                    e.target.value,
                                    "\nvalue",
                                    value
                                  );

                                  if (value < 0) value = 0;
                                  if (value > partners.length)
                                    value = partners.length; // Ensure it doesn't exceed partners.length
                                  await jurisdictionFormik.setFieldValue(
                                    "visa_for_investor",
                                    value
                                  );
                                }
                              }}
                              InputProps={{
                                endAdornment: (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    gap="3px"
                                  >
                                    {/* Decrement Button */}
                                    <IconButton
                                      aria-label="decrement"
                                      style={{ marginBottom: "10px" }}
                                      size="small"
                                      onClick={() => {
                                        console.log(
                                          "dec - values.visa_for_investor",
                                          jurisdictionFormik.values
                                            .visa_for_investor
                                        );

                                        if (
                                          !jurisdictionFormik.values
                                            .visa_for_investor &&
                                          jurisdictionFormik.values
                                            .visa_for_investor !== 0 &&
                                          jurisdictionFormik.values
                                            .visa_for_investor !== "0"
                                        ) {
                                          jurisdictionFormik.setFieldValue(
                                            "visa_for_investor",
                                            0
                                          );
                                        } else {
                                          jurisdictionFormik.setFieldValue(
                                            "visa_for_investor",
                                            Math.max(
                                              0,
                                              jurisdictionFormik.values
                                                .visa_for_investor - 1
                                            )
                                          );
                                        }
                                      }}
                                      disabled={
                                        jurisdictionFormik.values
                                          .visa_for_investor <= 0 ||
                                        isEverythingDisabled ||
                                        appComplete
                                      }
                                    >
                                      <MinimizeIcon fontSize="inherit" />
                                    </IconButton>
                                    {/* Increment Button */}
                                    <IconButton
                                      aria-label="increment"
                                      size="small"
                                      disabled={
                                        isEverythingDisabled ||
                                        appComplete ||
                                        jurisdictionFormik.values
                                          .visa_for_investor >=
                                          Math.min(15, partners.length) // Ensure it respects partners.length
                                      }
                                      onClick={() => {
                                        console.log(
                                          "inc - values.visa_for_investor",
                                          jurisdictionFormik.values
                                            .visa_for_investor
                                        );
                                        if (
                                          !jurisdictionFormik.values
                                            .visa_for_investor &&
                                          jurisdictionFormik.values
                                            .visa_for_investor !== 0 &&
                                          jurisdictionFormik.values
                                            .visa_for_investor !== "0"
                                        ) {
                                          jurisdictionFormik.setFieldValue(
                                            "visa_for_investor",
                                            0
                                          );
                                        } else {
                                          let visa_investor =
                                            jurisdictionFormik.values
                                              .visa_for_investor;
                                          console.log(
                                            "investor - inc - values.visa_for_investor = ",
                                            jurisdictionFormik.values
                                              .visa_for_investor
                                          );
                                          if (
                                            visa_investor &&
                                            visa_investor !== null &&
                                            visa_investor !== undefined &&
                                            visa_investor !== NaN
                                          ) {
                                            visa_investor = parseInt(
                                              visa_investor,
                                              10
                                            );
                                          } else {
                                            visa_investor = 0;
                                          }
                                          jurisdictionFormik.setFieldValue(
                                            "visa_for_investor",
                                            Math.min(
                                              Math.max(0, visa_investor + 1),
                                              partners.length // Limit to partners.length
                                            )
                                          );
                                        }
                                      }}
                                    >
                                      <AddIcon fontSize="inherit" />
                                    </IconButton>
                                  </Box>
                                ),
                              }}
                            />

                            {/* Inside Investor/partner - new field */}
                            {/* <Field
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label={
                                <Tooltip title="Investor/Partner Currently in the UAE">
                                  <span>Investor/Partner Currently in the UAE</span>
                                </Tooltip>
                              }                              
                              as={TextField}
                              name="visa_for_investor_inside"
                              type="number"
                              variant="outlined"
                              fullWidth
                              min={0}
                              disabled={isEverythingDisabled || appComplete}
                              onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
                              // value={ partners.length === 1 && !partners.some((item)=> !item?.nameEN?.length) ? Math.max(0, Math.min(values.visa_for_investor_inside, 0)) : Math.max(0, Math.min(values.visa_for_investor_inside, 15))}
                              value={jurisdictionFormik.values.visa_for_investor_inside}
                              error={Boolean(jurisdictionFormik.errors.visa_for_investor_inside && jurisdictionFormik.touched.visa_for_investor_inside)}
                              helperText={
                                jurisdictionFormik.errors.visa_for_investor_inside && jurisdictionFormik.touched.visa_for_investor_inside ? (
                                  <div className="text-red-500">{jurisdictionFormik.errors.visa_for_investor_inside}</div>
                                ) : null
                              }
                              onChange={async (e) => {
                                if (partners.length === 1 && partners.some((item) => item?.nameEN?.length === 0) === false) {
                                  let value = parseInt(e.target.value);
                                  console.log("c1 - e.target.value", e.target.value, "\nvalue", value);

                                  if (value < 0) value = 0;
                                  if (value > 1) value = 1;
                                  if ((value !== "0" && value !== 0) && (!value || value === undefined || value === null || value === "")) {
                                    value = 0;
                                  }
                                  await jurisdictionFormik.setFieldValue("visa_for_investor_inside", value);
                                } else {
                                  let value = parseInt(e.target.value, 10);
                                  console.log("c2 - e.target.value", e.target.value, "\nvalue", value);

                                  if (value <= 0) value = 0;
                                  if (value > 15) value = 15;
                                  await jurisdictionFormik.setFieldValue("visa_for_investor_inside", value);
                                }
                              }}
                              InputProps={{
                                endAdornment: (
                                  <Box display="flex" alignItems="center" gap="3px">
                                    <IconButton
                                      aria-label="decrement"
                                      style={{ marginBottom: '10px' }}
                                      size="small"
                                      onClick={() => {
                                        console.log("dec - values.visa_for_investor_inside", jurisdictionFormik.values.visa_for_investor_inside);

                                        if (!jurisdictionFormik.values.visa_for_investor_inside && jurisdictionFormik.values.visa_for_investor_inside !== 0 && jurisdictionFormik.values.visa_for_investor_inside !== "0") {
                                          jurisdictionFormik.setFieldValue("visa_for_investor_inside", 0);
                                        } else {
                                          jurisdictionFormik.setFieldValue("visa_for_investor_inside", Math.max(0, jurisdictionFormik.values.visa_for_investor_inside - 1));
                                        }
                                      }}
                                      disabled={jurisdictionFormik.values.visa_for_investor_inside <= 0 || isEverythingDisabled || appComplete}
                                    >
                                      <MinimizeIcon fontSize="inherit" />
                                    </IconButton>
                                    <IconButton
                                      aria-label="increment"
                                      size="small"
                                      disabled={
                                        (isEverythingDisabled || appComplete) ||
                                        (partners.length === 1 && !partners.some((item) => !item?.nameEN?.length))
                                          ? isEverythingDisabled || appComplete || parseInt(jurisdictionFormik.values.visa_for_investor_inside) >= 1
                                          : isEverythingDisabled || appComplete || parseInt(jurisdictionFormik.values.visa_for_investor_inside) >= 15
                                      }
                                      onClick={() => {
                                        console.log("inc - values.visa_for_investor_inside", jurisdictionFormik.values.visa_for_investor_inside);
                                        if (!jurisdictionFormik.values.visa_for_investor_inside && jurisdictionFormik.values.visa_for_investor_inside !== 0 && jurisdictionFormik.values.visa_for_investor_inside !== 0) {
                                          jurisdictionFormik.setFieldValue("visa_for_investor_inside", 0);
                                        } else {
                                          let visa_investor = jurisdictionFormik.values.visa_for_investor_inside;
                                          console.log("investor - inc - values.visa_for_investor_inside = ", jurisdictionFormik.values.visa_for_investor_inside);
                                          if (visa_investor && visa_investor !== null && visa_investor !== undefined && visa_investor !== NaN) {
                                            visa_investor = parseInt(visa_investor);
                                          } else {
                                            visa_investor = 0;
                                          }
                                          jurisdictionFormik.setFieldValue("visa_for_investor_inside", Math.max(0, visa_investor + 1));
                                        }
                                      }}
                                    >
                                      <AddIcon fontSize="inherit" />
                                    </IconButton>
                                  </Box>
                                ),
                              }}
                            /> */}

                            {/* Outside Manager/Employee - old field */}
                            <Field
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label={
                                <Tooltip title="Manager/Employee Currently outside of the UAE">
                                  <span>
                                    Manager/Employee Currently outside of UAE
                                  </span>
                                </Tooltip>
                              }
                              sx={{
                                "& .MuiFormLabel-root": {
                                  width: "115%",
                                  top:
                                    windowWidth > 983 && windowWidth < 1024
                                      ? "0px"
                                      : windowWidth < 640
                                      ? "0px"
                                      : "-15px",
                                  whiteSpace: "pre-wrap",
                                },
                              }}
                              as={TextField}
                              name="visa_for_manager"
                              type="number"
                              variant="outlined"
                              disabled={isEverythingDisabled || appComplete}
                              fullWidth
                              onKeyPress={(e) =>
                                e.key === "Enter" && e.preventDefault()
                              }
                              value={Math.max(
                                0,
                                Math.min(
                                  jurisdictionFormik.values.visa_for_manager,
                                  15
                                )
                              )}
                              error={
                                !!(
                                  ErrorMessage.name &&
                                  ErrorMessage.visa_for_manager
                                )
                              }
                              helperText={
                                <div className="text-red-500">
                                  <ErrorMessage
                                    name="visa_for_manager"
                                    sx={{ color: "red" }}
                                  />
                                </div>
                              }
                              onChange={async (e) => {
                                let value = parseInt(e.target.value, 10);
                                if (value <= 0) value = 0;
                                if (value > 15) value = 15; // Ensure value is not greater than 15
                                await jurisdictionFormik.setFieldValue(
                                  "visa_for_manager",
                                  value
                                );
                              }}
                              InputProps={{
                                endAdornment: (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    gap="3px"
                                  >
                                    <IconButton
                                      aria-label="add"
                                      size="small"
                                      style={{ marginBottom: "10px" }} // corrected style attribute name to marginBottom
                                      onClick={() =>
                                        handleDecrement(
                                          jurisdictionFormik.setFieldValue,
                                          jurisdictionFormik.values
                                            .visa_for_manager
                                        )
                                      } // corrected handleDecrement function call
                                      disabled={
                                        isEverythingDisabled ||
                                        appComplete ||
                                        jurisdictionFormik.values
                                          .visa_for_manager <= 0
                                      } // Disable if visa_for_manager count is 15 or more
                                    >
                                      <MinimizeIcon fontSize="inherit" />
                                    </IconButton>
                                    <IconButton
                                      aria-label="add"
                                      size="small"
                                      onClick={() =>
                                        handleIncrement(
                                          jurisdictionFormik.setFieldValue,
                                          jurisdictionFormik.values
                                            .visa_for_manager
                                        )
                                      } // Call your handleIncrement function here
                                      disabled={
                                        isEverythingDisabled ||
                                        appComplete ||
                                        parseInt(
                                          jurisdictionFormik.values
                                            .visa_for_manager
                                        ) >= 15
                                      } // Disable if visa_for_manager count is 15 or more
                                    >
                                      <AddIcon fontSize="inherit" />
                                    </IconButton>
                                  </Box>
                                ),
                              }}
                            />

                            {/* Inside Manager/Employee - new field */}

                            <Field
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label={
                                <Tooltip title="Manager/Employee Currently in the UAE">
                                  <span>
                                    Manager/Employee Currently in the UAE
                                  </span>
                                </Tooltip>
                              }
                              sx={{
                                // marginTop: windowWidth < 390 ? "-15px" : "0px",
                                "& .MuiFormLabel-root": {
                                  width: "115%",
                                  top:
                                    windowWidth > 902 && windowWidth < 1024
                                      ? "0px"
                                      : windowWidth < 640
                                      ? "0px"
                                      : "-15px",
                                  whiteSpace: "pre-wrap",
                                },
                              }}
                              as={TextField}
                              name="visa_for_manager_inside"
                              type="number"
                              variant="outlined"
                              disabled={isEverythingDisabled || appComplete}
                              fullWidth
                              onKeyPress={(e) =>
                                e.key === "Enter" && e.preventDefault()
                              }
                              value={Math.max(
                                0,
                                Math.min(
                                  jurisdictionFormik.values
                                    .visa_for_manager_inside,
                                  15
                                )
                              )}
                              error={
                                !!(
                                  ErrorMessage.name &&
                                  ErrorMessage.visa_for_manager_inside
                                )
                              }
                              helperText={
                                <div className="text-red-500">
                                  <ErrorMessage
                                    name="visa_for_manager_inside"
                                    sx={{ color: "red" }}
                                  />
                                </div>
                              }
                              onChange={async (e) => {
                                let value = parseInt(e.target.value, 10);
                                if (value <= 0) value = 0;
                                if (value > 15) value = 15; // Ensure value is not greater than 15
                                await jurisdictionFormik.setFieldValue(
                                  "visa_for_manager_inside",
                                  value
                                );
                              }}
                              InputProps={{
                                endAdornment: (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    gap="3px"
                                  >
                                    <IconButton
                                      aria-label="decrement"
                                      size="small"
                                      style={{ marginBottom: "10px" }} // corrected style attribute name to marginBottom
                                      onClick={() =>
                                        handleDecrementInside(
                                          jurisdictionFormik.setFieldValue,
                                          jurisdictionFormik.values
                                            .visa_for_manager_inside
                                        )
                                      } // corrected handleDecrement function call
                                      disabled={
                                        isEverythingDisabled ||
                                        appComplete ||
                                        jurisdictionFormik.values
                                          .visa_for_manager_inside <= 0
                                      } // Disable if visa_for_manager count is 0 or less
                                    >
                                      <MinimizeIcon fontSize="inherit" />
                                    </IconButton>
                                    <IconButton
                                      aria-label="increment"
                                      size="small"
                                      onClick={() =>
                                        handleIncrementInside(
                                          jurisdictionFormik.setFieldValue,
                                          jurisdictionFormik.values
                                            .visa_for_manager_inside
                                        )
                                      } // Call your handleIncrement function here
                                      disabled={
                                        isEverythingDisabled ||
                                        appComplete ||
                                        parseInt(
                                          jurisdictionFormik.values
                                            .visa_for_manager_inside
                                        ) >= 15
                                      } // Disable if visa_for_manager count is 15 or more
                                    >
                                      <AddIcon fontSize="inherit" />
                                    </IconButton>
                                  </Box>
                                ),
                              }}
                            />
                          </div>
                        </div>

                        <div className="lg:w-2.5/12 office-space-field-container">
                          <p className="text-start font-bold mb-2 text-[#1E1D1B] text-[16px]">
                            Type of office space
                          </p>
                          <Field
                            as={TextField}
                            name="officeSpace"
                            select
                            variant="outlined"
                            fullWidth
                            disabled={isEverythingDisabled}
                            error={
                              !!(ErrorMessage.name && ErrorMessage.officeSpace)
                            }
                            helperText={
                              <div className="text-red-500">
                                <ErrorMessage name="officeSpace" />
                              </div>
                            }
                            value={jurisdictionFormik.values.officeSpace} // Bind to Formik's state
                            onChange={(e) => {
                              const value = e.target.value;
                              console.log("officeSpace selected =", value);
                              jurisdictionFormik.setFieldValue(
                                "officeSpace",
                                value
                              ); // Update Formik state
                            }}
                          >
                            <MenuItem value="Virtual/Flexi Office">
                              <p>Virtual/Flexi Office</p>
                            </MenuItem>
                            <MenuItem value="Co-working space">
                              <p>Co-working space</p>
                            </MenuItem>
                            <MenuItem value="Dedicated Office">
                              <p>Dedicated Office</p>
                            </MenuItem>
                          </Field>
                        </div>

                        <div className="lg:w-2.5/12 jurisdiction-type-field-container">
                          <p className=" text-start font-bold mb-2 text-[#1E1D1B] text-[16px] ">
                            Type of Jurisdiction
                          </p>
                          <Field
                            as={TextField}
                            name="jurisdictionType"
                            select
                            variant="outlined"
                            fullWidth
                            disabled={isEverythingDisabled}
                            defaultValue="Not sure"
                            onChange={async (e) => {
                              if (e.target.value === "Main Land") {
                                setIsMainLand(true);
                                setIsFreeZone(false);
                                setInitialValues({...initialValues, jurisdictionType: "Main Land"});
                                jurisdictionFormik.setFieldValue("jurisdictionType", "Main Land");
                              } else if (e.target.value === "Free Zone") {
                                setIsFreeZone(true);
                                setIsMainLand(false);
                                setInitialValues({...initialValues, jurisdictionType: "Free Zone"});
                                jurisdictionFormik.setFieldValue("jurisdictionType", "Free Zone");
                              } else {
                                // For "Not sure", show both
                                setIsFreeZone(false);
                                setIsMainLand(false);
                                setInitialValues({...initialValues, jurisdictionType: "Not sure"});
                                jurisdictionFormik.setFieldValue("jurisdictionType", "Not sure");
                              }
                            }}
                          >
                            <MenuItem value="Main Land">
                              <p>Main Land</p>
                            </MenuItem>
                            <MenuItem value="Free Zone">
                              <p>Free Zone</p>
                            </MenuItem>
                            <MenuItem value="Not sure">
                              <p>Not sure</p>
                            </MenuItem>
                            {/* Add more MenuItem options as needed */}
                          </Field>
                        </div>
                      </div>

                      {/* selected activities here */}

                      <Box
                        px={0}
                        mt={4}
                        sx={{
                          height: "auto",
                          minHeight: "12vh",
                          paddingBottom: "10px",
                        }}
                      >
                        <Typography
                          variant="body2"
                          gutterBottom
                          sx={{
                            marginTop: "0px",
                            marginBottom: "2px",
                            fontWeight: 600,
                            fontSize: {
                              xs: "16px",
                              smc: "22px",
                            },
                          }}
                        >
                          Your selected activities:
                        </Typography>
                        <div className="scrollbar overflow-y-auto max-h-[110px] h-[fit-content]">
                          {deletingSubActivity ? (
                            <div className="relative max-h-[110px] overflow-hidden">
                              {/* Content Behind Loader */}
                              <div className="flex flex-wrap mt-3.5">
                                {selectedActivities.map((activity, index) => (
                                  <Tooltip
                                    key={index}
                                    placement="top"
                                    title={activity}
                                  >
                                    <div className="cursor-pointer wrapped-capsule px-3 py-2 bg-[#1E1D1B] rounded-[20px] text-white text-sm flex items-center justify-between mr-2 mb-2">
                                      <span className="wrapped-capsule">
                                        {activity}
                                      </span>
                                      <button
                                        className="p-1"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (appComplete === true) {
                                            return toast.warning(
                                              "Cannot change business activties because this application already complete"
                                            );
                                          } else if (
                                            deletingSubActivity === false &&
                                            appComplete === false
                                          ) {
                                            RemoveSubActivity(activity);
                                          }
                                        }}
                                      >
                                        <MdClose
                                          hidden={appComplete}
                                          className="w-4 h-4 text-white "
                                        />
                                      </button>
                                    </div>
                                  </Tooltip>
                                ))}
                              </div>

                              {/* Loader Overlay */}
                              <div
                                className="absolute inset-0 flex justify-center items-center h-full"
                                style={{
                                  zIndex: 10,
                                  backgroundColor: "rgba(255, 255, 255, 0.1)", // slight transparent layer
                                  backdropFilter: "blur(3px)", // blur effect
                                }}
                              >
                                <CircularProgress color="success" />
                              </div>
                            </div>
                          ) : (
                            <div className="flex flex-wrap mt-3.5 ">
                              {selectedActivities.map((activity, index) => (
                                <Tooltip placement="top" title={activity}>
                                  <div
                                    key={index}
                                    className="cursor-pointer wrapped-capsule px-3 py-2 bg-[#1E1D1B] rounded-[20px] text-white text-sm flex items-center justify-between mr-2 mb-2"
                                  >
                                    <span className="wrapped-capsule">
                                      {activity}
                                    </span>
                                    <button
                                      className="p-1"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (appComplete === true) {
                                          return toast.warning(
                                            "Cannot change business activties because this application already complete"
                                          );
                                        } else if (
                                          deletingSubActivity === false &&
                                          appComplete === false
                                        ) {
                                          RemoveSubActivity(activity);
                                        }
                                      }}
                                    >
                                      <MdClose
                                        hidden={appComplete}
                                        className="w-4 h-4 text-white"
                                      />
                                    </button>
                                  </div>
                                </Tooltip>
                              ))}
                            </div>
                          )}
                        </div>
                      </Box>
                      <Divider
                        className="mui-divider-custom"
                        textAlign="center"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          padding: "0", // Remove all padding from the Divider itself
                          paddingRight: "0px",
                          paddingLeft: "0px",
                          "&::before, &::after": {
                            content: '""',
                            flexGrow: 1, // Make the lines grow to fill available space
                            // borderBottom: '1px solid #c4c4c4', // Line styling,
                            // borderWidth:"1px",
                            borderColor: "#c4c4c4",
                            paddingRight: "0px",
                            paddingLeft: "0px",
                          },
                          "&::before": {
                            marginRight: "0px", // Space between the left line and the button
                            paddingRight: "0px",
                            paddingLeft: "0px",
                          },
                          "&::after": {
                            marginLeft: "0px", // Space between the right line and the button
                            paddingRight: "0px",
                            paddingLeft: "0px",
                          },
                          color: "#c4c4c4", // Optional text color if needed
                        }}
                      >
                        <div className="flex justify-center md:w-[100%]">
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: "#81ef6f",
                              fontWeight: 600,
                              color: "#1E1D1B",
                              borderRadius: "10px",
                              textTransform: "none",
                              boxShadow: "none",
                              px: 5,
                              py: 1,
                              "&:hover": {
                                backgroundColor: "#81EF6F",
                                boxShadow: "none",
                              },
                              display: "flex",
                              gap: "10px",
                            }}
                            disabled={jr || appComplete} // Example prop, adjust as needed
                            onClick={() =>
                              handleApiRequest(jurisdictionFormik.values, {
                                ...jurisdictionFormik.setFieldValue,
                              })
                            } // Example function
                          >
                            {/* Find my Jurisdiction  */} Calculate Your
                            Jurisdiction
                          </Button>
                        </div>
                      </Divider>

                      <Box
                        sx={{
                          height: "fit-content",
                          maxHeight:
                            windowWidth > 1280
                              ? `calc(100dvh - 90px - 20px - 32px - ${boxHeight}px - 80px - 250px)`
                              : "fit-content",
                          overflowY: "auto",
                        }}
                        ref={jurisdictionContainer}
                        className="overflow-y-auto scrollbar mt-2 find-jurisdiction-mobile-container"
                      >
                        {!isMainLand ? (
                          <>
                            {jr ? (
                              <div className="flex items-center text-center md:w-[100%] w-full justify-center overflow-hidden">
                                <video
                                  autoPlay
                                  loop
                                  muted
                                  playsInline
                                  className="max-h-[100%] w-full"
                                >
                                  <source src={JR_animation} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                            ) : (
                              <div className="w-[100%] mt-5">
                                {!allData?.length ||
                                suggestedJurisdictions?.length === 0 ? (
                                  isApiCalled && (
                                    <div className="items-center justify-center mt-2">
                                      <h3
                                        style={{
                                          fontSize: "23px",
                                          lineHeight: "23px",
                                          fontWeight: "700",
                                          textAlign: "center",
                                        }}
                                      >
                                        No Jurisdiction Available
                                      </h3>
                                      <p
                                        className="mt-3"
                                        style={{
                                          fontSize: "14px",
                                          lineHeight: "18px",
                                          fontWeight: "400",
                                          textAlign: "center",
                                        }}
                                      >
                                        There are no jurisdictions that offer
                                        combined licences for your business
                                        activity selection.
                                        <br />
                                        Please amend your activity selection.
                                      </p>
                                    </div>
                                  )
                                ) : (
                                  <div>
                                    <div
                                      hidden={
                                        suggestedJurisdictions.length > 0
                                          ? false
                                          : true
                                      }
                                    >
                                      {" "}
                                      {/* start of available jurisdictgion table */}
                                      <div
                                        className="w-[fit-content] overflow-x-hidden"
                                        style={{
                                          overflow: "hidden",
                                          width: "fit-content",
                                        }}
                                      >
                                        <p className="mt-3 font-semibold text-md sm:text-xl  text-[#1E1D1B]">
                                          Suggested Jurisdiction
                                        </p>
                                        <p className="text-sm sm:text-md lg:text-lg text-start font-thin text-[#1E1D1B]">
                                          Based on your inputs of business
                                          activity, number of partners, number
                                          of visas required and <br />
                                          office type, we have calculated the
                                          following jurisdiction to be the most
                                          cost efficient.
                                        </p>
                                      </div>
                                      <div
                                        className="w-full max-w-[100%] lg:min-w-[640px]"
                                        style={
                                          {
                                            // border:"2px solid red",
                                          }
                                        }
                                      >
                                        {/* table header div */}
                                        <div className="w-full md:pr-12 hidden lg:flex lg:min-w-[640px]">
                                          <p className="font-normal w-5/12 text-[#1E1D1B]"></p>
                                          <p className="mt-6 w-4/12 font-semibold text-end pr-7 text-sm text-[#1E1D1B]">
                                            Setup Cost
                                          </p>
                                          <p className="mt-6 w-3/12 pr-7 font-semibold text-end text-sm text-[#1E1D1B]">
                                            External Approval
                                          </p>
                                        </div>

                                        <div className="lg:min-w-[640px] w-full pt-[44px] lg:pt-0">
                                          {suggestedJurisdictions
                                            .filter((jurisdiction) => {
                                              if (isMainLand) {
                                                // If filtering for Mainland, only show non-freezone jurisdictions
                                                return !jurisdiction.is_freezone;
                                              } else if (isFreeZone) {
                                                // If filtering for Freezone, only show freezone jurisdictions
                                                return jurisdiction.is_freezone;
                                              } else {
                                                // If not sure, show all jurisdictions (both freezone and mainland)
                                                return true;
                                              }
                                            })
                                            .filter((jurisdiction) => {
                                              // Exclude jurisdictions with missing required data like name or upfront cost
                                              return (
                                                jurisdiction?.name &&
                                                jurisdiction?.upfront_cost !==
                                                  undefined
                                              );
                                            })
                                            ?.map((jurisdiction, index) => (
                                              <button
                                                key={index}
                                                type="button"
                                                disabled={isEverythingDisabled}
                                                className="w-full"
                                              >
                                                <div
                                                  className={`${
                                                    isEverythingDisabled
                                                      ? "unselectable"
                                                      : ""
                                                  } flex mt-2 gap-4 justify-start items-center w-full lg:min-w-[640px]`}
                                                  onClick={() => {
                                                    // Handle jurisdiction selection here
                                                    //console.log("selected jurisdiction is = ", jurisdiction)
                                                    jurisdictionFormik.setFieldValue(
                                                      "selectedJurisdiction",
                                                      jurisdiction
                                                    );
                                                    setSelectedJurisdiction(
                                                      jurisdiction
                                                    );
                                                    setSelectedJurisdictionID(
                                                      jurisdiction?.id
                                                    );
                                                    setServerJurisdictionData(
                                                      jurisdiction?.id
                                                    );
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      cursor: "pointer",
                                                      backgroundColor:
                                                        (selectedJurisdictionID ===
                                                          serverJurisdictionData &&
                                                          selectedJurisdictionID ===
                                                            jurisdiction?.id) || // condition was OR casuing to show bogus selection
                                                        jurisdiction?.id ===
                                                          jurisdictionFormik
                                                            .values
                                                            .selectedJurisdiction
                                                            ?.id
                                                          ? "#E6FCE2"
                                                          : "white",
                                                      border:
                                                        (selectedJurisdictionID ===
                                                          serverJurisdictionData &&
                                                          selectedJurisdictionID ===
                                                            jurisdiction?.id) || // condition was OR casuing to show bogus selection
                                                        jurisdiction?.id ===
                                                          jurisdictionFormik
                                                            .values
                                                            .selectedJurisdiction
                                                            ?.id
                                                          ? "2px solid #81EF6F" // selection of jurisdiction
                                                          : "",
                                                    }}
                                                    className="w-full lg:min-w-[640px] px-4 w-full border-2 gap-2 flex justify-between items-center rounded-lg py-3"
                                                  >
                                                    {/* interior jurisdiction design */}

                                                    {/* Desktop Design */}
                                                    <div className="hidden lg:flex w-full gap-2 justify-between items-center">
                                                      <p className="font-normal w-6/12 text-start text-[#1E1D1B]">
                                                        {jurisdiction?.name}
                                                      </p>
                                                      <p className="font-semibold text-sm w-3/12 text-end text-[#1E1D1B]">
                                                        AED{" "}
                                                        <span className="pl-1">
                                                          {jurisdiction?.upfront_cost
                                                            ?.toString()
                                                            .replace(
                                                              /\B(?=(\d{3})+(?!\d))/g,
                                                              ","
                                                            )}
                                                        </span>
                                                      </p>
                                                      <p className="font-semibold text-sm w-3/12 text-end pr-16  text-[#1E1D1B]">
                                                        {
                                                          jurisdiction?.external_approval
                                                        }
                                                      </p>
                                                    </div>

                                                    {/* Mobile UI */}
                                                    <div className="block lg:hidden w-full gap-2">
                                                      <div className="flex justify-between w-full">
                                                        <p className="font-normal text-start text-[#1E1D1B] text-xs sm:text-sm">
                                                          {jurisdiction?.name}
                                                        </p>

                                                        <span
                                                          style={{
                                                            margin:
                                                              "-8px -12px 0px 0px",
                                                          }}
                                                          className="block lg:hidden"
                                                          onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleClickOpen(
                                                              jurisdiction
                                                            );
                                                          }}
                                                        >
                                                          <ExclaimationIcon />
                                                        </span>
                                                      </div>

                                                      <div className="mt-3 flex w-full jurisdiction-cost-approval-container">
                                                        <Typography
                                                          sx={{
                                                            fontSize: {
                                                              xs: "13px",
                                                              smc: "14px",
                                                            },
                                                            display: "flex",
                                                            gap: "4px",
                                                          }}
                                                        >
                                                          External Approval:
                                                          <p className="font-semibold text-sm text-[#1E1D1B]">
                                                            {
                                                              jurisdiction?.external_approval
                                                            }
                                                          </p>
                                                        </Typography>

                                                        <Typography
                                                          sx={{
                                                            fontSize: {
                                                              xs: "13px",
                                                              smc: "14px",
                                                            },
                                                            display: "flex",
                                                            gap: "4px",
                                                          }}
                                                        >
                                                          Setup Cost:
                                                          <p className="font-semibold text-sm text-[#1E1D1B]">
                                                            AED{" "}
                                                            {jurisdiction?.upfront_cost
                                                              ?.toString()
                                                              .replace(
                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                ","
                                                              )}
                                                          </p>
                                                        </Typography>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <span className="hidden lg:block">
                                                    <InfoOutlinedIcon
                                                      sx={{
                                                        fontSize: "40px",
                                                        color: "#1E1D1B",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleClickOpen(
                                                          jurisdiction
                                                        );
                                                      }}
                                                    />
                                                  </span>
                                                </div>
                                              </button>
                                            ))}
                                        </div>
                                      </div>
                                      {open && selectedJurisdiction && (
                                        //this is the dialog for the selected jurisdiction
                                        <BootstrapDialog
                                          onClose={handleClose}
                                          aria-labelledby="customized-dialog-title"
                                          open={open}
                                          PaperProps={{
                                            style: {
                                              minWidth: {
                                                xs: "50%",
                                                smc: "617.5px",
                                              },
                                              minHeight: "40%",
                                            },
                                          }}
                                        >
                                          <DialogTitle
                                            sx={{
                                              m: 0,
                                              p: 2,
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                              marginLeft: "10px",
                                              marginTop: "15px",
                                            }}
                                            id="customized-dialog-title"
                                          >
                                            {/* jurisdiction info */}
                                            {selectedJurisdiction?.license_cost_breakdown?.some(
                                              (cost) =>
                                                cost.department !== null &&
                                                cost.department?.length > 0
                                            )
                                              ? "Cost Breakdown"
                                              : selectedJurisdiction?.name}
                                            <br />
                                            {selectedJurisdiction?.license_cost_breakdown?.some(
                                              (cost) =>
                                                cost.department !== null &&
                                                cost.department?.length > 0
                                            ) ? (
                                              <span
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "normal",
                                                  color: "#555",
                                                  display: "block",
                                                  marginTop: "2px",
                                                }}
                                              >
                                                {selectedJurisdiction?.name}
                                              </span>
                                            ) : (
                                              <span
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "normal",
                                                  color: "#555",
                                                  display: "block",
                                                  marginTop: "2px",
                                                }}
                                              >
                                                The price we have quoted
                                                includes the following charges:
                                              </span>
                                            )}
                                          </DialogTitle>
                                          <IconButton
                                            aria-label="close"
                                            onClick={handleClose}
                                            sx={{
                                              position: "absolute",
                                              right: 8,
                                              top: 8,
                                              color: (theme) =>
                                                theme.palette.grey[500],
                                            }}
                                          >
                                            <CloseIcon
                                              sx={{ color: "#1E1D1B" }}
                                            />
                                          </IconButton>
                                          <DialogContent
                                            sx={{
                                              padding: {
                                                xs: "20px 5px",
                                                smc: "20px 24px",
                                              },
                                            }}
                                          >
                                            {selectedJurisdiction?.license_cost_breakdown?.some(
                                              (cost) =>
                                                cost.department !== null &&
                                                cost.department?.length > 0
                                            ) ? (
                                              <div
                                                className="justify-start flex flex-col gap-[20px] sm:gap-[60px]"
                                                style={{ fontSize: "14px" }}
                                              >
                                                {/* Render the custom table layout */}
                                                <table
                                                  style={{
                                                    width: "100%",
                                                    borderCollapse: "collapse",
                                                    fontSize: "14px",
                                                  }}
                                                >
                                                  <thead>
                                                    <tr
                                                      style={{
                                                        borderBottom:
                                                          "0px solid #000",
                                                      }}
                                                    >
                                                      <th
                                                        style={{
                                                          textAlign: "left",
                                                          padding: "5px",
                                                        }}
                                                      >
                                                        Item
                                                      </th>
                                                      <th
                                                        style={{
                                                          textAlign: "left",
                                                          padding: "5px",
                                                        }}
                                                      >
                                                        Department
                                                      </th>
                                                      <th
                                                        style={{
                                                          textAlign: "right",
                                                          padding: "5px",
                                                          minWidth: "83px",
                                                        }}
                                                      >
                                                        Cost (AED)
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {[
                                                      ...selectedJurisdiction?.cost_breakdown,
                                                      ...selectedJurisdiction?.license_cost_breakdown,
                                                    ]
                                                      ?.filter(
                                                        (cost) => cost.price > 0
                                                      )
                                                      ?.map((cost, index) => (
                                                        <tr
                                                          key={index}
                                                          style={{
                                                            backgroundColor:
                                                              index % 2 === 0
                                                                ? "#E9E9E4"
                                                                : "#FFF",
                                                            height: "18px",
                                                          }}
                                                        >
                                                          <td
                                                            style={{
                                                              padding: "5px",
                                                              fontSize: "14px",
                                                            }}
                                                          >
                                                            {formatKey(
                                                              cost?.name
                                                            )}
                                                          </td>
                                                          <td
                                                            style={{
                                                              padding: "5px",
                                                              fontSize: "14px",
                                                            }}
                                                          >
                                                            {cost?.department?.includes(
                                                              "_"
                                                            )
                                                              ? cost?.department
                                                                  ?.split("_")
                                                                  .map(
                                                                    (item) =>
                                                                      item + " "
                                                                  )
                                                              : cost?.department}
                                                          </td>
                                                          <td
                                                            style={{
                                                              padding: "5px",
                                                              fontSize: "14px",
                                                              textAlign:
                                                                "right",
                                                            }}
                                                          >
                                                            {cost?.price}
                                                          </td>
                                                        </tr>
                                                      ))}
                                                    {/* <tr
                                                      style={{
                                                        height: '18px',
                                                      }}
                                                    >
                                                      <td></td>
                                                      <td></td>
                                                      


                                                  </tr> */}
                                                  </tbody>
                                                </table>
                                                {/* 
                                            <div className="flex flex-row mt-[20px] gap-[5px] w-full" style={{ padding: '5px', textAlign: 'right', justifyContent:"flex-end"}}> 
                                                  <b>Sub Total:</b> 
                                                  {
                                                    selectedJurisdiction?.license_cost_breakdown?.some(
                                                      (cost) => cost.department !== null && cost.department?.length > 0
                                                    ) ? selectedJurisdiction?.license_cost_breakdown.map((item) => item.price).reduce((sum, num) => sum + num, 0) : ""
                                                  }
                                              </div> */}
                                              </div>
                                            ) : (
                                              // Render the old layout
                                              <div
                                                className="justify-start flex flex-col sm:flex-row gap-[20px] sm:gap-[60px]"
                                                style={{
                                                  marginLeft: "20px",
                                                  fontSize: "14px",
                                                }}
                                              >
                                                <ul
                                                  style={{
                                                    listStyleType: "disc",
                                                    marginRight: 0,
                                                    paddingLeft: "1em",
                                                  }}
                                                >
                                                  <span className="font-bold ml-[-15px]">
                                                    {selectedJurisdiction?.license_cost_breakdown
                                                      ? "For License:"
                                                      : ""}
                                                  </span>
                                                  {selectedJurisdiction?.license_cost_breakdown
                                                    ?.filter(
                                                      (cost) =>
                                                        cost.price > 0 ||
                                                        (typeof cost?.price ===
                                                          "string" &&
                                                          cost.price.length)
                                                    )
                                                    ?.map((cost, index) => (
                                                      <li
                                                        key={index}
                                                        className="ml-4"
                                                      >
                                                        {formatKey(cost?.name)}
                                                        <span>
                                                          :{" "}
                                                          {typeof cost?.price ===
                                                          "string"
                                                            ? cost?.price
                                                            : `AED ${cost?.price}`}
                                                        </span>
                                                      </li>
                                                    ))}
                                                </ul>
                                                <ul
                                                  style={{
                                                    listStyleType: "disc",
                                                    marginRight: 0,
                                                    paddingLeft: "1em",
                                                  }}
                                                >
                                                  <span className="font-bold ml-[-15px]">
                                                    {selectedJurisdiction?.cost_breakdown?.some(
                                                      (item) => item?.price > 0
                                                    )
                                                      ? "For Visas:"
                                                      : ""}
                                                  </span>
                                                  {selectedJurisdiction?.cost_breakdown
                                                    ?.filter(
                                                      (cost) =>
                                                        cost.price > 0 ||
                                                        typeof cost?.price ===
                                                          "string"
                                                    )
                                                    ?.map((cost, index) => (
                                                      <li
                                                        key={index}
                                                        className="ml-4"
                                                      >
                                                        {formatKey(cost?.name)}
                                                        <span>
                                                          :{" "}
                                                          {typeof cost?.price ===
                                                          "string"
                                                            ? cost?.price
                                                            : `AED ${cost?.price}`}{" "}
                                                        </span>
                                                      </li>
                                                    ))}
                                                </ul>
                                              </div>
                                            )}
                                          </DialogContent>
                                        </BootstrapDialog>
                                      )}
                                    </div>{" "}
                                    {/* end of available jurisdiction table */}
                                    <Divider
                                      hidden={
                                        !otherJurisdictions?.length
                                          ? true
                                          : false
                                      }
                                      sx={{
                                        mt: 3,
                                        mb: 2,
                                        minWidth: "100%",
                                        borderColor: "#c4c4c4",
                                      }}
                                    />
                                    <div
                                      className="w-full lg:min-w-[640px]"
                                      hidden={
                                        !otherJurisdictions?.length
                                          ? true
                                          : false
                                      }
                                    >
                                      <p className="mt-3 font-semibold text-md sm:text-xl text-[#1E1D1B]">
                                        Other Available Jurisdictions
                                      </p>
                                      {otherJurisdictions.length === 0 && (
                                        <div className="flex items-center justify-center mt-2">
                                          No available jurisdictions
                                        </div>
                                      )}
                                    </div>
                                    {otherJurisdictions
                                      .filter((jurisdiction) => {
                                        if (isMainLand) {
                                          // If filtering for Mainland, only show non-freezone jurisdictions
                                          return !jurisdiction.is_freezone;
                                        } else if (isFreeZone) {
                                          // If filtering for Freezone, only show freezone jurisdictions
                                          return jurisdiction.is_freezone;
                                        } else {
                                          // If not sure, show all jurisdictions (both freezone and mainland)
                                          return true;
                                        }
                                      })
                                      .filter((jurisdiction) => {
                                        // Exclude jurisdictions with missing required data like name or upfront cost
                                        return (
                                          jurisdiction?.name &&
                                          jurisdiction?.upfront_cost !==
                                            undefined
                                        );
                                      })
                                      .map((jurisdiction, index) => (
                                        <button
                                          type="button"
                                          key={index}
                                          disabled={isEverythingDisabled}
                                          className="w-full"
                                        >
                                          <div
                                            className="w-full lg:min-w-[640px] flex mt-2 gap-4 justify-start items-center"
                                            onClick={() => {
                                              // Assuming jurisdictionFormik.setFieldValue, setSelectedJurisdictionID, and setServerJurisdictionData are synchronous,
                                              // but showing how you might handle them if they were asynchronous.
                                              jurisdictionFormik.setFieldValue(
                                                "selectedJurisdiction",
                                                jurisdiction
                                              );
                                              setSelectedJurisdiction(
                                                jurisdiction
                                              );
                                              setSelectedJurisdictionID(
                                                jurisdiction?.id
                                              );
                                              setServerJurisdictionData(
                                                jurisdiction?.id
                                              );

                                              // Example of an actual asynchronous operation:
                                              // const response = await fetchSomeData(jurisdiction?.id);
                                            }}
                                          >
                                            <div
                                              style={{
                                                cursor: "pointer",
                                                backgroundColor:
                                                  (selectedJurisdictionID ===
                                                    serverJurisdictionData &&
                                                    selectedJurisdictionID ===
                                                      jurisdiction?.id) ||
                                                  jurisdiction?.id ===
                                                    jurisdictionFormik.values
                                                      .selectedJurisdiction?.id
                                                    ? "#E6FCE2"
                                                    : "white",
                                                border:
                                                  (selectedJurisdictionID ===
                                                    serverJurisdictionData &&
                                                    selectedJurisdictionID ===
                                                      jurisdiction?.id) ||
                                                  jurisdiction?.id ===
                                                    jurisdictionFormik.values
                                                      .selectedJurisdiction?.id
                                                    ? "2px solid #81EF6F"
                                                    : "",
                                              }}
                                              className="w-full lg:min-w-[640px] px-4 w-full border-2 gap-2 flex justify-between items-center rounded-lg py-3"
                                            >
                                              {/* Desktop Design */}
                                              <div className="hidden lg:flex w-full gap-2 justify-between items-center">
                                                <p className="font-normal w-6/12 text-start text-[#1E1D1B]">
                                                  {jurisdiction?.name}
                                                </p>
                                                <p className="font-semibold text-sm w-3/12 text-end text-[#1E1D1B]">
                                                  AED{" "}
                                                  <span className="pl-1">
                                                    {jurisdiction?.upfront_cost
                                                      ?.toString()
                                                      .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                      )}
                                                  </span>
                                                </p>
                                                <p className="font-semibold text-sm w-3/12 text-end pr-16  text-[#1E1D1B]">
                                                  {
                                                    jurisdiction?.external_approval
                                                  }
                                                </p>
                                              </div>

                                              {/* Mobile UI */}
                                              <div className="block lg:hidden w-full gap-2">
                                                <div className="flex justify-between w-full">
                                                  <p className="font-normal text-start text-[#1E1D1B] text-xs sm:text-sm">
                                                    {jurisdiction?.name}
                                                  </p>

                                                  <span
                                                    style={{
                                                      margin:
                                                        "-8px -12px 0px 0px",
                                                    }}
                                                    className="block lg:hidden"
                                                    onClick={(event) => {
                                                      event.stopPropagation();
                                                      handleClickOpenOther(
                                                        jurisdiction
                                                      );
                                                    }}
                                                  >
                                                    <ExclaimationIcon />
                                                  </span>
                                                </div>

                                                <div className="mt-3 flex w-full jurisdiction-cost-approval-container">
                                                  <Typography
                                                    sx={{
                                                      fontSize: {
                                                        xs: "13px",
                                                        smc: "14px",
                                                      },
                                                      display: "flex",
                                                      gap: "4px",
                                                    }}
                                                  >
                                                    External Approval:
                                                    <p className="font-semibold text-sm text-[#1E1D1B]">
                                                      {
                                                        jurisdiction?.external_approval
                                                      }
                                                    </p>
                                                  </Typography>

                                                  <Typography
                                                    sx={{
                                                      fontSize: {
                                                        xs: "13px",
                                                        smc: "14px",
                                                      },
                                                      display: "flex",
                                                      gap: "4px",
                                                    }}
                                                  >
                                                    Setup Cost:
                                                    <p className="font-semibold text-sm text-[#1E1D1B]">
                                                      AED{" "}
                                                      {jurisdiction?.upfront_cost
                                                        ?.toString()
                                                        .replace(
                                                          /\B(?=(\d{3})+(?!\d))/g,
                                                          ","
                                                        )}
                                                    </p>
                                                  </Typography>
                                                </div>
                                              </div>
                                            </div>

                                            {/* <p className="font-normal w-7/12 text-[#1E1D1B]">
                                              {jurisdiction.name}
                                            </p>
                                            <p className="font-semibold w-3/12 text-end text-[#1E1D1B]">
                                              AED{" "}
                                              <span className="pl-1">
                                                {jurisdiction.upfront_cost}
                                              </span>
                                            </p>
                                            <p className="font-semibold w-2/12 text-end  text-[#1E1D1B]">
                                              
                                              <span className="pl-1">
                                                {jurisdiction?.external_approval}
                                              </span>
                                            </p> */}
                                            <span className="hidden lg:block">
                                              <InfoOutlinedIcon
                                                sx={{
                                                  fontSize: "40px",
                                                  color: "#1E1D1B",
                                                  cursor: "pointer",
                                                }}
                                                onClick={(event) => {
                                                  event.stopPropagation();
                                                  handleClickOpenOther(
                                                    jurisdiction
                                                  );
                                                }}
                                              />
                                            </span>
                                          </div>
                                        </button>
                                      ))}
                                    {openOtherDialog &&
                                      selectedOtherJurisdiction && (
                                        <BootstrapDialog
                                          onClose={handleCloseOther}
                                          open={openOtherDialog}
                                          PaperProps={{
                                            style: {
                                              minWidth: "45%",
                                              maxWidth:
                                                windowWidth < 639
                                                  ? "100%"
                                                  : "70%",
                                              minHeight: "40%",
                                            },
                                          }}
                                        >
                                          {/* Dialog content for Other Available Jurisdiction */}
                                          <DialogTitle
                                            sx={{
                                              m: 0,
                                              p: 2,
                                              fontSize: "18px",
                                              fontWeight: "bold",
                                              marginLeft: "20px",
                                              marginTop: "15px",
                                            }}
                                            id="customized-dialog-title"
                                          >
                                            {selectedOtherJurisdiction?.license_cost_breakdown?.some(
                                              (cost) =>
                                                cost.department !== null &&
                                                cost.department?.length > 0
                                            )
                                              ? "Cost Breakdown"
                                              : selectedOtherJurisdiction?.name}

                                            <br />
                                            <span
                                              style={{
                                                fontSize: "16px",
                                                fontWeight: "normal",
                                                color: "#555",
                                                display: "block",
                                                marginTop: "2px",
                                              }}
                                            >
                                              {selectedOtherJurisdiction?.license_cost_breakdown?.some(
                                                (cost) =>
                                                  cost.department !== null &&
                                                  cost.department?.length > 0
                                              )
                                                ? selectedOtherJurisdiction?.name
                                                : "The price we have quoted includes the following charges:"}
                                            </span>
                                          </DialogTitle>
                                          <IconButton
                                            aria-label="close"
                                            onClick={handleCloseOther}
                                            sx={{
                                              position: "absolute",
                                              right: 8,
                                              top: 8,
                                              color: (theme) =>
                                                theme.palette.grey[500],
                                            }}
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                          <DialogContent
                                            sx={{
                                              padding: {
                                                xs: selectedJurisdiction?.license_cost_breakdown?.some(
                                                  (cost) =>
                                                    cost.department !== null &&
                                                    cost.department?.length > 0
                                                )
                                                  ? "20px 5px"
                                                  : "20px 24px",
                                                smc: "20px 24px",
                                              },
                                            }}
                                          >
                                            {selectedOtherJurisdiction?.license_cost_breakdown?.some(
                                              (cost) =>
                                                cost.department !== null &&
                                                cost.department?.length > 0
                                            ) ? (
                                              <div
                                                className="justify-start flex flex-col gap-[20px] sm:gap-[60px]"
                                                style={{ fontSize: "14px" }}
                                              >
                                                {/* Render the custom table layout */}
                                                <table
                                                  style={{
                                                    width: "100%",
                                                    borderCollapse: "collapse",
                                                    fontSize: "14px",
                                                  }}
                                                >
                                                  <thead>
                                                    <tr
                                                      style={{
                                                        borderBottom:
                                                          "0px solid #000",
                                                      }}
                                                    >
                                                      <th
                                                        style={{
                                                          textAlign: "left",
                                                          padding: "5px",
                                                        }}
                                                      >
                                                        Item
                                                      </th>
                                                      <th
                                                        style={{
                                                          textAlign: "left",
                                                          padding: "5px",
                                                        }}
                                                      >
                                                        Department
                                                      </th>
                                                      <th
                                                        style={{
                                                          textAlign: "right",
                                                          padding: "5px",
                                                          minWidth: "83px",
                                                        }}
                                                      >
                                                        Cost (AED)
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {[
                                                      ...selectedOtherJurisdiction?.cost_breakdown,
                                                      ...selectedOtherJurisdiction?.license_cost_breakdown,
                                                    ]
                                                      ?.filter(
                                                        (cost) => cost.price > 0
                                                      )
                                                      ?.map((cost, index) => (
                                                        <tr
                                                          key={index}
                                                          style={{
                                                            backgroundColor:
                                                              index % 2 === 0
                                                                ? "#E9E9E4"
                                                                : "#FFF",
                                                            height: "18px",
                                                          }}
                                                        >
                                                          <td
                                                            style={{
                                                              padding: "5px",
                                                              fontSize: "14px",
                                                            }}
                                                          >
                                                            {formatKey(
                                                              cost?.name
                                                            )}
                                                          </td>
                                                          <td
                                                            style={{
                                                              padding: "5px",
                                                              fontSize: "14px",
                                                            }}
                                                          >
                                                            {cost?.department?.includes(
                                                              "_"
                                                            )
                                                              ? cost?.department
                                                                  ?.split("_")
                                                                  .map(
                                                                    (item) =>
                                                                      item + " "
                                                                  )
                                                              : cost?.department}
                                                          </td>
                                                          <td
                                                            style={{
                                                              padding: "5px",
                                                              fontSize: "14px",
                                                              textAlign:
                                                                "right",
                                                            }}
                                                          >
                                                            {cost?.price}
                                                          </td>
                                                        </tr>
                                                      ))}
                                                    {/* <tr
                                                      style={{
                                                        height: '18px',
                                                      }}
                                                    >
                                                      <td></td>
                                                      <td></td>
                                                      


                                                  </tr> */}
                                                  </tbody>
                                                </table>
                                                {/* 
                                            <div className="flex flex-row mt-[20px] gap-[5px] w-full" style={{ padding: '5px', textAlign: 'right', justifyContent:"flex-end"}}> 
                                                  <b>Sub Total:</b> 
                                                  {
                                                    selectedJurisdiction?.license_cost_breakdown?.some(
                                                      (cost) => cost.department !== null && cost.department?.length > 0
                                                    ) ? selectedJurisdiction?.license_cost_breakdown.map((item) => item.price).reduce((sum, num) => sum + num, 0) : ""
                                                  }
                                              </div> */}
                                              </div>
                                            ) : (
                                              // Render the old layout
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  justifyContent: "start",
                                                  flexDirection: {
                                                    xs: "column",
                                                    smc: "row",
                                                  },
                                                  gap: {
                                                    xs: "20px",
                                                    smc: "60px",
                                                  },
                                                  marginLeft: "20px",
                                                  fontSize: "14px",
                                                }}
                                              >
                                                <ul
                                                  style={{
                                                    listStyleType: "disc",
                                                    marginRight: 0,
                                                    paddingLeft: "1em",
                                                  }}
                                                >
                                                  <span className="font-bold ml-[-15px]">
                                                    {selectedOtherJurisdiction?.license_cost_breakdown
                                                      ? "For License:"
                                                      : ""}
                                                  </span>
                                                  {selectedOtherJurisdiction?.license_cost_breakdown
                                                    ?.filter(
                                                      (cost) =>
                                                        cost.price > 0 ||
                                                        (typeof cost?.price ===
                                                          "string" &&
                                                          cost.price.length)
                                                    )
                                                    ?.map((cost, index) => (
                                                      <li
                                                        key={index}
                                                        className="ml-4"
                                                      >
                                                        {formatKey(cost?.name)}
                                                        <span>
                                                          : {cost?.price}
                                                        </span>
                                                      </li>
                                                    ))}
                                                </ul>
                                                <ul
                                                  style={{
                                                    listStyleType: "disc",
                                                    marginRight: 0,
                                                    paddingLeft: "1em",
                                                  }}
                                                >
                                                  <span className="font-bold ml-[-15px]">
                                                    {selectedOtherJurisdiction?.cost_breakdown
                                                      ? "For Visas:"
                                                      : ""}
                                                  </span>
                                                  {selectedOtherJurisdiction?.cost_breakdown
                                                    ?.filter(
                                                      (cost) =>
                                                        cost.price > 0 ||
                                                        typeof cost?.price ===
                                                          "string"
                                                    )
                                                    ?.map((cost, index) => (
                                                      <li
                                                        key={index}
                                                        className="ml-4"
                                                      >
                                                        {formatKey(cost?.name)}
                                                        <span>
                                                          : {cost?.price}
                                                        </span>
                                                      </li>
                                                    ))}
                                                </ul>
                                                {/* Additional logic for displaying visa costs here */}
                                              </Box>
                                            )}
                                          </DialogContent>
                                        </BootstrapDialog>
                                      )}
                                  </div>
                                )}

                                <div className="mt-2 text-center">
                                  {/* error doesn't appear if field touch isn't true */}
                                  {jurisdictionFormik.errors
                                    .selectedJurisdiction?.id &&
                                    jurisdictionFormik.touched
                                      .selectedJurisdiction && (
                                      <>
                                        {/* || ( ( suggestedJurisdictions.length > 0 || otherJurisdictions.length > 0) && !selectedJurisdictionID ) && ( */}
                                        <div
                                          ref={selectJurisdictionRef}
                                          className="text-red-500"
                                        >
                                          {
                                            jurisdictionFormik.errors
                                              .selectedJurisdiction?.id
                                          }{" "}
                                          {/* || "Please select a jurisdiction before proceeding"  */}
                                        </div>
                                      </>
                                    )}
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {jr ? (
                              <div className="flex items-center text-center justify-center mt-5">
                                <img
                                  src={JR_animation}
                                  alt="Loading animation"
                                  className="md:w-2/3 filter sepia(100%)"
                                />
                              </div>
                            ) : (
                              <div className="w-[90%]">
                                {suggestedJurisdictions.length === 0 ? (
                                  <div className="items-center justify-center mt-2">
                                    <h3
                                      style={{
                                        fontSize: "23px",
                                        lineHeight: "23px",
                                        fontWeight: "700",
                                        textAlign: "center",
                                      }}
                                    >
                                      No Jurisdiction Available
                                    </h3>
                                    <p
                                      className="mt-3"
                                      style={{
                                        fontSize: "14px",
                                        lineHeight: "18px",
                                        fontWeight: "400",
                                        textAlign: "center",
                                      }}
                                    >
                                      There are no jurisdictions that offer
                                      combined licences for your business
                                      activity selection.
                                      <br />
                                      Please amend your activity selection.
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <div>
                                      <p className="mt-3 font-semibold text-xl text-[#1E1D1B]">
                                        Suggested Jurisdiction
                                      </p>
                                      <p className="text-sm text-start font-thin text-[#8C8C8C]">
                                        Based on your inputs of business
                                        activity, number of partners, number of
                                        visa_for_manager required and <br />{" "}
                                        office type, we have calculated the
                                        following jurisdiction to be the most
                                        cost efficient.
                                      </p>
                                    </div>

                                    <div className="w-full pr-12 flex">
                                      <p className="font-normal w-6/12 text-[#1E1D1B]"></p>
                                      <p className="mt-6 w-4/12 font-semibold text-end pr-10 text-sm text-[#1E1D1B]">
                                        Setup Cost
                                      </p>
                                      <p className="mt-6 w-2/12 font-semibold text-end text-sm text-[#1E1D1B]">
                                        External Approval
                                      </p>
                                    </div>

                                    {suggestedJurisdictions
                                      .filter((jurisdiction) => {
                                        if (isMainLand) {
                                          // If filtering for Mainland, only show non-freezone jurisdictions
                                          return !jurisdiction.is_freezone;
                                        } else if (isFreeZone) {
                                          // If filtering for Freezone, only show freezone jurisdictions
                                          return jurisdiction.is_freezone;
                                        } else {
                                          // If not sure, show all jurisdictions (both freezone and mainland)
                                          return true;
                                        }
                                      })
                                      .map((jurisdiction, index) => (
                                        <div
                                          key={index}
                                          className="flex mt-2 gap-4 justify-start items-center"
                                          onClick={() => {
                                            // Handle jurisdiction selection here
                                            jurisdictionFormik.setFieldValue(
                                              "selectedJurisdiction",
                                              jurisdiction
                                            );
                                            setSelectedJurisdiction(
                                              jurisdiction
                                            );
                                            setSelectedJurisdictionID(
                                              jurisdiction?.id
                                            );
                                            setServerJurisdictionData(
                                              jurisdiction?.id
                                            );
                                          }}
                                        >
                                          <div
                                            style={{
                                              cursor: "pointer",
                                              backgroundColor:
                                                (selectedJurisdictionID ===
                                                  serverJurisdictionData &&
                                                  selectedJurisdictionID ===
                                                    jurisdiction?.id) ||
                                                jurisdiction?.id ===
                                                  jurisdictionFormik.values
                                                    .selectedJurisdiction?.id
                                                  ? "#E6FCE2"
                                                  : "white",
                                              border:
                                                (selectedJurisdictionID ===
                                                  serverJurisdictionData &&
                                                  selectedJurisdictionID ===
                                                    jurisdiction?.id) ||
                                                jurisdiction?.id ===
                                                  jurisdictionFormik.values
                                                    .selectedJurisdiction?.id
                                                  ? "2px solid #81EF6F"
                                                  : "",
                                            }}
                                            className="px-4 w-full border-2 gap-2 flex justify-between items-center rounded-lg py-3"
                                          >
                                            <p className="font-normal w-7/12 text-[#1E1D1B]">
                                              {jurisdiction?.name}
                                            </p>
                                            <p className="font-semibold w-3/12 text-end text-[#1E1D1B]">
                                              AED{" "}
                                              <span className="pl-1">
                                                {jurisdiction.upfront_cost}
                                              </span>
                                            </p>
                                            <p className="font-semibold w-2/12 text-end pr-8  text-[#1E1D1B]">
                                              {jurisdiction?.external_approval}
                                            </p>
                                          </div>
                                          <InfoOutlinedIcon
                                            sx={{
                                              fontSize: "40px",
                                              color: "#1E1D1B",
                                              cursor: "pointer",
                                            }}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              handleClickOpen(jurisdiction);
                                            }}
                                          />
                                        </div>
                                      ))}

                                    {open && selectedJurisdiction && (
                                      <BootstrapDialog
                                        onClose={handleClose}
                                        aria-labelledby="customized-dialog-title"
                                        open={open}
                                        PaperProps={{
                                          style: {
                                            minWidth: "40%",
                                            minHeight: "60%", // or you can use '500px' for a fixed width
                                            // or you can use '500px' for a fixed width
                                            // maxWidth: 'none', // This will allow the dialog to use the width you set
                                          },
                                        }}
                                      >
                                        <DialogTitle
                                          sx={{
                                            m: 0,
                                            p: 2,
                                            fontSize: "18px",
                                            fontWeight: "bold",
                                            marginLeft: "20px",
                                            marginTop: "15px",
                                          }}
                                          id="customized-dialog-title"
                                        >
                                          {selectedJurisdiction?.name}
                                          <br />
                                          <span
                                            style={{
                                              fontSize: "16px",
                                              fontWeight: "normal",
                                              color: "#555",
                                              display: "block",
                                              marginTop: "2px",
                                            }}
                                          >
                                            The price we have quoted includes
                                            the following charges:
                                          </span>
                                        </DialogTitle>
                                        <IconButton
                                          aria-label="close"
                                          onClick={handleClose}
                                          sx={{
                                            position: "absolute",
                                            right: 8,
                                            top: 8,
                                            color: (theme) =>
                                              theme.palette.grey[500],
                                          }}
                                        >
                                          <CloseIcon />
                                        </IconButton>
                                        <DialogContent>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "start",
                                              flexDirection: {
                                                xs: "column",
                                                smc: "row",
                                              },
                                              gap: "60px",
                                              marginLeft: "20px",
                                              fontSize: "14px",
                                            }}
                                          >
                                            {/* Additional logic for displaying visa costs here */}
                                            <ul
                                              style={{
                                                listStyleType: "disc",
                                                marginRight: 0,
                                                paddingLeft: "1em",
                                              }}
                                            >
                                              <span className="font-bold ml-[-15px]">
                                                For Visas
                                              </span>
                                              {/* <span className="text-bold">{selectedJurisdiction.cost_breakdown}</span> */}
                                              {selectedJurisdiction?.license_cost_breakdown?.map(
                                                (cost, index) => (
                                                  <li key={index}>
                                                    {formatKey(cost?.name)}
                                                    <span>: {cost.price}</span>
                                                    {/* {cost.price} */}
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </div>
                                        </DialogContent>
                                      </BootstrapDialog>
                                    )}

                                    <Divider
                                      sx={{
                                        borderBottomWidth: 2,
                                        mt: 3,
                                        mb: 2,
                                      }}
                                    />
                                    <p className="mt-3 font-semibold text-xl text-[#1E1D1B]">
                                      Other Available Jurisdictions
                                    </p>
                                    {otherJurisdictions.length === 0 && (
                                      <div className="flex items-center justify-center mt-2">
                                        No available jurisdictions
                                      </div>
                                    )}

                                    {otherJurisdictions
                                      .filter(
                                        (jurisdiction) =>
                                          !jurisdiction.is_freezone
                                      )
                                      .map((jurisdiction, index) => (
                                        <div
                                          key={index}
                                          className="flex mt-2 gap-4 justify-start items-center"
                                          onClick={() => {
                                            // Assuming jurisdictionFormik.setFieldValue, setSelectedJurisdictionID, and setServerJurisdictionData are synchronous,
                                            // but showing how you might handle them if they were asynchronous.
                                            jurisdictionFormik.setFieldValue(
                                              "selectedJurisdiction",
                                              jurisdiction
                                            );
                                            setSelectedJurisdiction(
                                              jurisdiction
                                            );
                                            setSelectedJurisdictionID(
                                              jurisdiction?.id
                                            );
                                            setServerJurisdictionData(
                                              jurisdiction?.id
                                            );

                                            // Example of an actual asynchronous operation:
                                            // const response = await fetchSomeData(jurisdiction?.id);
                                          }}
                                        >
                                          <div
                                            style={{
                                              cursor: "pointer",
                                              backgroundColor:
                                                (selectedJurisdictionID ===
                                                  serverJurisdictionData &&
                                                  selectedJurisdictionID ===
                                                    jurisdiction?.id) ||
                                                jurisdiction?.id ===
                                                  jurisdictionFormik.values
                                                    .selectedJurisdiction?.id
                                                  ? "#E6FCE2"
                                                  : "white",
                                              border:
                                                (selectedJurisdictionID ===
                                                  serverJurisdictionData &&
                                                  selectedJurisdictionID ===
                                                    jurisdiction?.id) ||
                                                jurisdiction?.id ===
                                                  jurisdictionFormik.values
                                                    .selectedJurisdiction?.id
                                                  ? "2px solid #81EF6F"
                                                  : "",
                                            }}
                                            className="px-4 w-full gap-2 border-2 flex justify-between items-center rounded-lg py-3"
                                          >
                                            <p className="font-normal w-7/12 text-[#1E1D1B]">
                                              {jurisdiction?.name}
                                            </p>
                                            <p className="font-semibold w-3/12 text-end text-[#1E1D1B]">
                                              AED{" "}
                                              <span className="pl-1">
                                                {jurisdiction?.upfront_cost}
                                              </span>
                                            </p>
                                            <p className="font-semibold w-2/12 text-end  text-[#1E1D1B]">
                                              <span className="pr-8">
                                                {
                                                  jurisdiction?.external_approval
                                                }
                                              </span>
                                            </p>
                                          </div>
                                          {/* <p className="font-normal w-7/12 text-[#1E1D1B]">
                                            {jurisdiction.name}
                                          </p>
                                          <p className="font-semibold w-3/12 text-end text-[#1E1D1B]">
                                            AED{" "}
                                            <span className="pl-1">
                                              {jurisdiction.upfront_cost}
                                            </span>
                                          </p>
                                          <p className="font-semibold w-2/12 text-end  text-[#1E1D1B]">
                                            
                                            <span className="pl-1">
                                              {jurisdiction?.external_approval}
                                            </span>
                                          </p> */}
                                          <InfoOutlinedIcon
                                            sx={{
                                              fontSize: "40px",
                                              color: "#1E1D1B",
                                              cursor: "pointer",
                                            }}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              handleClickOpenOther(
                                                jurisdiction
                                              );
                                            }}
                                          />
                                        </div>
                                      ))}
                                  </div>
                                )}

                                <div className="mt-2 text-center">
                                  {jurisdictionFormik.errors
                                    .selectedJurisdiction?.length &&
                                    jurisdictionFormik.touched
                                      .selectedJurisdiction && (
                                      <div className="text-red-500">
                                        {
                                          jurisdictionFormik.errors
                                            .selectedJurisdiction
                                        }
                                      </div>
                                    )}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </Box>

                      <div
                        hidden={jr || !allData}
                        className="hidden arrow-container w-full justify-center flex lg:hidden mt-4"
                      >
                        {/* Left Arrow */}
                        <div
                          className="arrow-left cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleArrowClick({
                              direction: "left",
                              ref: jurisdictionContainer,
                            });
                          }}
                          onMouseDown={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleMouseDown("left");
                          }}
                          onMouseUp={handleMouseUp}
                          onMouseLeave={handleMouseUp}
                          onTouchStart={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleMouseDown("left");
                          }}
                          onTouchEnd={handleMouseUp}
                        >
                          <KeyboardArrowLeftOutlinedIcon
                            sx={{
                              fontSize: "50px",
                              strokeWidth: "1px",
                              stroke: "white",
                            }}
                          />
                        </div>

                        {/* Right Arrow */}
                        <div
                          className="arrow-right cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleArrowClick({
                              direction: "right",
                              ref: jurisdictionContainer,
                            });
                          }}
                          onMouseDown={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleMouseDown("right");
                          }}
                          onMouseUp={handleMouseUp}
                          onMouseLeave={handleMouseUp}
                          onTouchStart={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleMouseDown("right");
                          }}
                          onTouchEnd={handleMouseUp}
                        >
                          <KeyboardArrowLeftOutlinedIcon
                            sx={{
                              fontSize: "50px",
                              transform: "rotate(180deg)",
                              strokeWidth: "1px",
                              stroke: "white",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>

                {/*  scroll for inner body */}

                {/* end before footer button*/}

                <Box
                  sx={{
                    alignItems: "flex-end",
                    display: "flex",
                    gap: "0.1rem",
                    borderTop: "2px solid #c4c4c4",
                    mt: 1,
                  }}
                >
                  <Button
                    variant="contained"
                    fullWidth
                    className="three-btn-row"
                    sx={{
                      backgroundColor: "rgb(212 212 216)",
                      fontWeight: 600,
                      boxShadow: "none",
                      "&:hover": {
                        backgroundColor: "#81EF6F",
                        boxShadow: "none",
                      },
                      color: "#1E1D1B",
                      borderRadius: "0px",
                      textTransform: "none",
                      maxWidth: windowWidth < 640 ? "48%" : "28%",
                    }}
                    onClick={onBack}
                  >
                    Previous
                  </Button>
                  <Button
                    variant="contained"
                    fullWidth
                    disabled={jr}
                    className="three-btn-row"
                    sx={{
                      backgroundColor: "rgb(212 212 216)",
                      fontWeight: 600,
                      boxShadow: "none",
                      display: windowWidth < 640 ? "none" : "flex",
                      "&:hover": {
                        backgroundColor: "#81EF6F",
                        boxShadow: "none",
                      },
                      color: "#1E1D1B",
                      borderRadius: "0px",
                      textTransform: "none",
                      maxWidth: "28%",
                    }}
                    onClick={async () => {
                      const visa_for_managerNumber = jurisdictionFormik.values
                        .visa_for_manager
                        ? parseInt(
                            jurisdictionFormik.values.visa_for_manager,
                            10
                          )
                        : 0;
                      const visa_for_investorNumber = jurisdictionFormik.values
                        .visa_for_investor
                        ? parseInt(
                            jurisdictionFormik.values.visa_for_investor,
                            10
                          )
                        : 0;

                      const visa_for_managerNumber_inside = jurisdictionFormik
                        .values.visa_for_manager_inside
                        ? parseInt(
                            jurisdictionFormik.values.visa_for_manager_inside,
                            10
                          )
                        : 0;
                      const visa_for_investorNumber_inside = jurisdictionFormik
                        .values.visa_for_investor_inside
                        ? parseInt(
                            jurisdictionFormik.values.visa_for_investor_inside,
                            10
                          )
                        : 0;

                      console.log(
                        "jurisdictionFormik = ",
                        jurisdictionFormik.values.selectedJurisdiction
                      );

                      if (jurisdictionFormik.errors.selectedJurisdiction) {
                        // jurisdictionFormik.setFieldTouched('selectedJurisdiction', true, true);

                        selectJurisdictionRef.current?.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });
                      }

                      if (
                        visa_for_managerNumber +
                          visa_for_investorNumber +
                          visa_for_managerNumber_inside +
                          visa_for_investorNumber_inside >
                        15
                      ) {
                        return toast.error(
                          "The sum of visas for manager and investor cannot exceed 15.",
                          { toastId: "visaError" }
                        );
                        // return; // Prevent the API request from being triggered
                      }

                      if (
                        isEverythingDisabled ||
                        isEmptyJurisdiction?.length === 0 ||
                        !isApiCalled
                      ) {
                        handleIsEverythingDisabled("exit");
                      } else {
                        const errors = await jurisdictionFormik.validateForm();

                        if (Object.keys(errors).length === 0) {
                          // handleSubmit(values, true);
                          handleSubmit(
                            {
                              ...jurisdictionFormik.values,
                              selectedJurisdiction: { ...selectedJurisdiction },
                            },
                            true
                          );
                        } else {
                          jurisdictionFormik.submitForm();
                        }
                      }
                    }}
                  >
                    Save & Exit
                  </Button>
                  <Button
                    variant="contained"
                    fullWidth
                    className="three-btn-row"
                    disabled={jr}
                    sx={{
                      backgroundColor: "#81ef6f",
                      fontWeight: 600,
                      color: "#1E1D1B",
                      borderRadius: "0px",
                      textTransform: "none",
                      boxShadow: "none",
                      "&:hover": {
                        backgroundColor: "#81EF6F",
                        boxShadow: "none",
                      },
                      display: "flex",
                      gap: "10px",
                    }}
                    type={isEverythingDisabled ? "button" : "submit"} // Correctly apply the conditional type here
                    onClick={() => {
                      console.log(
                        "jurisdictionFormik = ",
                        jurisdictionFormik.values.selectedJurisdiction
                      );
                      console.log(
                        "jurisdictionFormik errors = ",
                        jurisdictionFormik.errors,
                        jurisdictionFormik.touched
                      );

                      if (
                        jurisdictionFormik.errors.selectedJurisdiction?.length
                      ) {
                        jurisdictionFormik.setFieldTouched(
                          "selectedJurisdiction",
                          true
                        );
                        jurisdictionFormik.submitForm();
                        selectJurisdictionRef.current?.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });
                      }

                      const visa_for_managerNumber = jurisdictionFormik.values
                        .visa_for_manager
                        ? parseInt(
                            jurisdictionFormik.values.visa_for_manager,
                            10
                          )
                        : 0;
                      const visa_for_investorNumber = jurisdictionFormik.values
                        .visa_for_investor
                        ? parseInt(
                            jurisdictionFormik.values.visa_for_investor,
                            10
                          )
                        : 0;

                      const visa_for_managerNumber_inside = jurisdictionFormik
                        .values.visa_for_manager_inside
                        ? parseInt(
                            jurisdictionFormik.values.visa_for_manager_inside,
                            10
                          )
                        : 0;
                      const visa_for_investorNumber_inside = jurisdictionFormik
                        .values.visa_for_investor_inside
                        ? parseInt(
                            jurisdictionFormik.values.visa_for_investor_inside,
                            10
                          )
                        : 0;
                      console.log(
                        "visa_for_managerNumber + visa_for_investorNumber + visa_for_managerNumber_inside + visa_for_investorNumber_inside = ",
                        visa_for_managerNumber +
                          visa_for_investorNumber +
                          visa_for_managerNumber_inside +
                          visa_for_investorNumber_inside
                      );

                      if (
                        visa_for_managerNumber +
                          visa_for_investorNumber +
                          visa_for_managerNumber_inside +
                          visa_for_investorNumber_inside >
                        15
                      ) {
                        toast.error(
                          "The sum of visas for manager and investor cannot exceed 15.",
                          { toastId: "visaError" }
                        );
                        return; // Prevent the API request from being triggered
                      } else if (
                        isEverythingDisabled &&
                        visa_for_managerNumber +
                          visa_for_investorNumber +
                          visa_for_managerNumber_inside <=
                          15
                      ) {
                        setSelectedJurisdictionID(null);
                        handleIsEverythingDisabled("next");
                      }
                    }}
                  >
                    Next Step
                    {loading && (
                      <svg
                        className="animate-spin w-6 h-6"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                      </svg>
                    )}
                  </Button>
                </Box>
              </Box>
            </Form>
          </Formik>
        </>
      )}
    </>
  );
}

export default SelectJurisdiction;
